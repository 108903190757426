import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Provider } from '@rollbar/react';
import axios from 'axios';

import Loader from './components/partials/loader/Loader';
import Home from './components/base/home/Home';
import Page from './components/base/page/Page';
import Share from './components/base/share/Share';
import Error404 from './components/base/error/Error';
import Expired from './components/base/expired/Expired';

const appVersion = '3.0.7';

const api = axios.create({
  headers: { Pragma: 'no-cache' },
});

const rollbarConfig = {
  accessToken: '5047cc4a81a8452db37994f95e99284e',
  captureUncaught: true,
  captureUnhandledRejections: true,
  payload: {
    environment: 'production',
    client: {
      javascript: {
        code_version: '1.0.0',
        source_map_enabled: true
      }
    }
  }
};

let lastScrollTop = 0;

class App extends Component {

  constructor(props) {
    super(props);
    this.state = {
      customDomain: false,
      studioDomain: false,
      brand: false,
      loadRouting: false
    }
  }

  componentWillMount() {
    this.setState({
      customDomain: window.DOMAIN,
      studioDomain: window.STUDIO,
      brand: window.BRAND,
      loadRouting: true
    })
  }

  /* Show / Hide mobile dontent scroll hint */
  handleScroll = (event) => {
    if (window.innerWidth > 768) {
      return true;
    }

    const scrollTop = event.target.scrollTop;

    // Only when scroll up or down
    if (scrollTop != lastScrollTop) {
      const collection = document.getElementsByClassName('anchor');
      const myElements = Array.from(collection);
      const viewportCenter = window.innerHeight / 2;

      myElements.forEach(el => {
        const rect = el.getBoundingClientRect();
        const elementCenter = rect.top + rect.height / 2 + 100;

        if (elementCenter >= viewportCenter - rect.height / 2 && elementCenter <= viewportCenter + rect.height / 2) {
          el.classList.add('visible');
        } else {
          el.classList.remove('visible');
        }
      });
    } else {
      const visibleHint = document.querySelector('.anchor.visible');
      if (visibleHint) {
        visibleHint.classList.remove('visible');
      }
    }

    lastScrollTop = scrollTop; // Update the last scroll position
  };

  render() {
    return (
      <Provider config={rollbarConfig}>
        <Router>
          <div data-version={appVersion} className="content-wrapper">
            {this.state.loadRouting &&
            <div className="app-content" id="app-content-scroll-wrapper" onScroll={this.handleScroll}>
              {this.state.customDomain &&
              <Switch>
                <Route exact path="/404" component={Error404}/>
                <Route exact path="/expired" component={Expired}/>
                <Route exact path="/" render={(props) => <Page brand={this.state.brand} domain={this.state.customDomain} studio={this.state.studioDomain} {...props}/>}/>
                <Route exact path="/share/:hash/:asset" render={(props) => <Share brand={this.state.brand} domain={this.state.customDomain} studio={this.state.studioDomain} {...props}/>}/>
                <Route exact path="/:menuslug" render={(props) => <Page brand={this.state.brand} domain={this.state.customDomain} studio={this.state.studioDomain} {...props}/>}/>
                <Route exact path="/:menuslug/:pageslug" render={(props) => <Page brand={this.state.brand} domain={this.state.customDomain} studio={this.state.studioDomain} {...props}/>}/>
                <Route exact path="/:menuslug/:pageslug/:subslug" render={(props) => <Page brand={this.state.brand} domain={this.state.customDomain} studio={this.state.studioDomain} {...props}/>}/>
                <Route component={Home}/>
              </Switch>
              }

              {!this.state.customDomain &&
              <Switch>
                <Route exact path="/" render={(props) => <Home studio={this.state.studioDomain} {...props}/>}/>
                <Route exact path="/404" component={Error404}/>
                <Route exact path="/expired" component={Expired}/>
                <Route exact path="/:brand" render={(props) => <Page brand={this.state.brand} studio={this.state.studioDomain} {...props}/>}/>
                <Route exact path="/:brand/share/:hash/:asset" render={(props) => <Share brand={this.state.brand} studio={this.state.studioDomain} {...props}/>}/>
                <Route exact path="/:brand/:menuslug" render={(props) => <Page brand={this.state.brand} studio={this.state.studioDomain} {...props}/>}/>
                <Route exact path="/:brand/:menuslug/:pageslug" render={(props) => <Page brand={this.state.brand} studio={this.state.studioDomain} {...props}/>}/>
                <Route exact path="/:brand/:menuslug/:pageslug/:subslug" render={(props) => <Page brand={this.state.brand} studio={this.state.studioDomain} {...props}/>}/>
                <Route component={Home}/>
              </Switch>
              }
            </div>
            }

            <Loader />
          </div>
        </Router>
      </Provider>
    );
  }
}

export default App;
