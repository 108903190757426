import React, { Component } from 'react';
import axios from "axios";
import { NavLink, withRouter } from "react-router-dom";
import Heading    from 'components/modules/heading/Heading';
import Grid       from 'components/modules/grid/Grid';
import Dodont     from 'components/modules/do.dont/Dodont';
import Spacer     from 'components/modules/spacer/Spacer';
import Colors     from 'components/modules/colors/Colors';
import Carousel   from 'components/modules/carousel/Carousel';
import Downloads  from 'components/modules/downloads/Downloads';
import Typography from 'components/modules/typography/Typography';
import Content    from 'components/modules/content.grid/Content';
import loadingRing from 'images/loading_ring.svg';
import './Search.scss';

class Search extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchOpened: false,
      searchInputFocused: false,
      expanded: [],
      brandId: 0,
      searchResultItems: [],
      firstItem: [{title: ''}],
      activeMenuId: 0
    }
  }

  _toggleSearchPopup = () => {
    if(!window.searchOpened){
      window.searchOpened = true;
    } else {
      window.searchOpened = false;
    }

    var searchHeaderDiv = document.querySelectorAll('.search-popup-frame-header');
    var mainSearchInput = document.getElementsByClassName('js-main-search-input')[0];

    setTimeout(function(){
      searchHeaderDiv[0].parentNode.closest('.search-popup').style.removeProperty("left");
      searchHeaderDiv[0].parentNode.closest('.search-popup').style.removeProperty("top");
    }, 250);
    if(window.searchOpened) {
      searchHeaderDiv[0].parentNode.closest('.search-core.js-search-core').classList.add('show-search-popup');
    } else {
      searchHeaderDiv[0].parentNode.closest('.search-core.js-search-core').classList.remove('show-search-popup');
    }

    setTimeout(function(){
      if(window.searchOpened) {
        if(window.searchResultItems.length === 0){
          mainSearchInput.focus({preventScroll: true});
        } else {
          mainSearchInput.blur();
        }
      } else {
          mainSearchInput.blur();
      }
    }, 250);
  }

  _searchPopupOnMouseOver  = () => {
    var elems =  document.querySelectorAll('.js-trigger-search-popup .search-trigger-icon-text-box');
    elems.forEach(el => {
      if(el.getAttribute('data-font-size') >= 14){
        el.classList.add('small-one');
      } else {
        el.classList.remove('small-one');
      }
      el.querySelector('.search-trigger-icon-text-box-deggree-symbol').style.fontSize = parseInt(el.getAttribute('data-font-size'))+4+'px';
      el.querySelector('.search-trigger-icon-text-box-deggree-symbol').style.marginTop = '-4px';
    });
  }

  _closeSearchPopup = () => {
    var searchHeaderDiv = document.querySelectorAll('.search-popup-frame-header');
    setTimeout(function(){
      searchHeaderDiv[0].parentNode.closest('.search-popup').style.removeProperty("left");
      searchHeaderDiv[0].parentNode.closest('.search-popup').style.removeProperty("top");
    }, 250);

    searchHeaderDiv[0].parentNode.closest('.search-core.js-search-core').classList.remove('show-search-popup');

    var mainSearchInput = document.getElementsByClassName('js-main-search-input')[0];
    this.setState({
      searchOpened: false
    });
    window.searchOpened = false;

    setTimeout(function(){
      mainSearchInput.blur();
    }, 250);
    document.body.classList.remove('searchOpened')
  }

  hex2rgba = (color, alpha = 1) => {
    if(color !== undefined){
      var hex   = color.replace('#', '');
      var r = parseInt(hex.length === 3 ? hex.slice(0, 1).repeat(2) : hex.slice(0, 2), 16);
      var g = parseInt(hex.length === 3 ? hex.slice(1, 2).repeat(2) : hex.slice(2, 4), 16);
      var b = parseInt(hex.length === 3 ? hex.slice(2, 3).repeat(2) : hex.slice(4, 6), 16);
      return 'rgba(' + r + ', ' + g + ', ' + b + ', ' + alpha + ')';
    }
  };

  hexToHSL = function(H) {
    // function to keep search box always visible
    if(H !== undefined){
      // Convert hex to RGB first
      let r = 0, g = 0, b = 0;
      if (H.length === 4) {
        r = "0x" + H[1] + H[1];
        g = "0x" + H[2] + H[2];
        b = "0x" + H[3] + H[3];
      } else if (H.length === 7) {
        r = "0x" + H[1] + H[2];
        g = "0x" + H[3] + H[4];
        b = "0x" + H[5] + H[6];
      }
      // Then to HSL
      r /= 255;
      g /= 255;
      b /= 255;
      let cmin = Math.min(r,g,b),
          cmax = Math.max(r,g,b),
          delta = cmax - cmin,
          h = 0,
          //s = 0,
          l = 0;

      if (delta === 0)
        h = 0;
      else if (cmax === r)
        h = ((g - b) / delta) % 6;
      else if (cmax === g)
        h = (b - r) / delta + 2;
      else
        h = (r - g) / delta + 4;

      h = Math.round(h * 60);

      if (h < 0)
        h += 360;

      l = (cmax + cmin) / 2;
      l = +(l * 100).toFixed(1);
      if(l < 20) {
        return "rgba(255,255,255, "+(10-l/4)+"% )";
      } else {
        return "rgba(0,0,0, "+(17-l/10)+"% )";
      }
    }
  }

  componentDidMount() {
    var reactThis = this;
    var isSearching = false;
    var element = document.querySelectorAll('.js-search-core');
    var destination = document.querySelectorAll('.brand-content');

    var isMac = /mac/i.test(navigator.userAgentData ? navigator.userAgentData.platform : navigator.platform);
    var elems = document.querySelectorAll('.js-trigger-search-popup');
    elems.forEach(el => {
      if(!isMac){
        el.classList.add('os-windows');
      }
    });

    var elems2 =  document.querySelectorAll('.js-trigger-search-popup .search-trigger-icon-text-box');
    elems2.forEach(el => {
      if(el.getAttribute('data-font-size') >= 14){
        el.classList.add('small-one');
      } else {
        el.classList.remove('small-one');
      }
      el.querySelector('.search-trigger-icon-text-box-deggree-symbol').style.fontSize = parseInt(el.getAttribute('data-font-size'))+4+'px';
      el.querySelector('.search-trigger-icon-text-box-deggree-symbol').style.marginTop = '-4px';
    });

    var resultsRelevantPlaceholderTexts = ['Another result can be found in the "textvarchange" section.',
    'There is another result in the "textvarchange" section.',
    'There is also another result under the "textvarchange" heading.',
    'The "textvarchange" section has more information you might find interesting',
    'Here is more results you might find interesting in "textvarchange" section'];

    var swiped_guide_x_times = localStorage.getItem('swiped_guide_x_times');

    if(swiped_guide_x_times > 1) {
      document.querySelector('.search-popup-result-carousel-item-intro-swipe-guide').style.display = 'none';
      document.body.classList.remove('howtoguideopened');
    } else {
      document.body.classList.add('howtoguideopened');
    }

    destination[0].after(element[0]);

    window.searchResultItems = [];
    window.resultsLoaded = false;
    reactThis.setState({
      searchResultItems: [],
      firstItem: [{title: ''}],
    });

    var mainSearchInput = element[0].getElementsByClassName('js-main-search-input')[0];
    var searchPopupFormTagHintEnter = document.body; //element[0].getElementsByClassName('js-search-popup-form-tag-hint-enter')[0];

    if ('CSS' in window && CSS.supports('backdrop-filter', 'blur()')) {
      // FlexBox is supported...
      element[0].classList.remove('no-backdrop-filter-blur')
    } else {
      // No FlexBox support...
      element[0].classList.add('no-backdrop-filter-blur')
    }

    mainSearchInput.addEventListener("focus", function(){
      window.searchInputFocused = true;
      reactThis.setState({
        searchInputFocused: true
      });
    });

    mainSearchInput.addEventListener("blur", function(){
      window.searchInputFocused = false;
      reactThis.setState({
        searchInputFocused: false
      });
    });

    //show enter HINT
    mainSearchInput.addEventListener('input', function (e) {
      if(e.target.value.length > 0) {
        searchPopupFormTagHintEnter.classList.add('hide-search-hand');
      } else {
        searchPopupFormTagHintEnter.classList.remove('hide-search-hand');
      }
      if(e.target.value.length > 0) {
        searchPopupFormTagHintEnter.classList.add('show-tag-hint');
      } else {
        searchPopupFormTagHintEnter.classList.remove('show-tag-hint');
      }
    });

    window.addEventListener("keydown", (e) => {
      if (e.keyCode === 71 && (isMac ? e.metaKey : e.ctrlKey)) {
        e.preventDefault();
        this._toggleSearchPopup();
      }

      if(e.key === "Escape") {
        reactThis._closeSearchPopup();
      }

      if(e.key === "Enter") {
        if(window.searchOpened && !e.target.closest('.search-box-append-tags')){
          if(document.querySelector('.search-popup-result-carousel-item.active .js-go-to-result')){
            simulateClick(document.querySelector('.search-popup-result-carousel-item.active .js-go-to-result'))
          }
        }
      }

      if(e.key === "Backspace") {
        if(window.searchOpened && !isSearching && document.querySelector('.resizing-input.js-resizing-input.main-search-box-div input').value.length < 1 ){
          var elements = document.querySelectorAll('.search-tag-box.js-search-tag-box .search-tag-box-delete');
          if(elements) {
            elements.forEach((node, index) => {
              if(parseInt(index+1) === parseInt(elements.length)){
                node.parentNode.remove();
                generateSearchData();
              }
            });
          }
        }
      }
    });

    // search input box functionality
    mainSearchInput.addEventListener('keyup', function (e) {
      if ( e.keyCode === 13 ) {
        if(mainSearchInput.value.length > 0) {
          createSearchTag(mainSearchInput.value, element);
          mainSearchInput.value = '';
          mainSearchInput.setAttribute('placeholder', 'Type a keyword here...');
          if(document.querySelectorAll('.search-tag-box .js-resizing-input').length === 1) {
            mainSearchInput.setAttribute('placeholder', 'Specify your search by adding color, author or other keyword...')
          }
          if(document.querySelectorAll('.search-tag-box .js-resizing-input').length === 2) {
            mainSearchInput.setAttribute('placeholder', 'Try to rephrase keywords...')
          }
          searchPopupFormTagHintEnter.classList.remove('show-tag-hint');

          // bind resize tag boxes stuff
          document.querySelectorAll('.js-resizing-input input').forEach(el => {
            el.addEventListener('keypress', function (ke) {
              if (ke.which && ke.charCode) {
                var c = String.fromCharCode(ke.keyCode | ke.charCode);
                resizeForText(ke.target, ke.target.value + c);
              }
            });
            // Backspace event only fires for keyup / 8 for backspace, 46 for delete
            el.addEventListener('keyup', function (ke) {
              if(ke.keyCode === 8 || ke.keyCode === 46) {
                if(ke.target.value.length === 0) {
                  if(ke.target.classList.contains('main-search-input')){
                  } else {
                    ke.target.parentNode.parentNode.remove();
                    el.removeEventListener('keyup');
                    el.removeEventListener('keypress');
                  }
                }
                resizeForText(ke.target, ke.target.value);
              }
            });
            // resize tag boxes on initial
            resizeForText(el, el.value);
          });
          // load search data then blur input
          generateSearchData();
          mainSearchInput.blur();
        }
      }
    });

    document.addEventListener('click', function (event) {
      // If the clicked element does not have and is not contained by an element with the .click-me class, ignore it
      if (!event.target.closest('.js-search-core') && !event.target.closest('.js-trigger-search-popup') && !window.searchSwiping) {
        reactThis._closeSearchPopup();
      }
      if(event.target.closest('.fixed-layer-popup')) {
        reactThis._closeSearchPopup();
      }
      if (event.target.closest('.js-search-popup-form-tag-hint-enter')) {
        const enterEvent = new KeyboardEvent("keyup", {
          view: window,
          keyCode: 13,
          bubbles: true,
          cancelable: true
        });
        mainSearchInput.dispatchEvent(enterEvent);

      } else if (event.target.closest('.js-search-popup-form-tag-clear-enter')) {
        if(!isSearching){
          document.querySelectorAll('.search-tag-box').forEach(node => {
            node.remove();
          });
          resetSearchData();
        }
      } else if (event.target.closest('.search-tag-box-delete')) {
        if(!isSearching){
          event.target.closest('.search-tag-box-delete').parentNode.remove();
          generateSearchData();
        }
      } else if(event.target.closest('.search-jump-to-unrelevant-btn')){
        event.preventDefault();
        searchPopupFormTagHintEnter.classList.add('unrelevant-view-called');
      } else if (event.target.closest('.js-go-to-result')){
        reactThis._closeSearchPopup();
      } else if (event.target.closest('.search-popup-bottom-dot')) {
        if(document.body.classList.contains('howtoguideopened')) {
         document.body.classList.remove('howtoguideopened');
         document.querySelector('.search-popup-result-carousel-item-intro-swipe-guide').style.left = '-120%';
         var swiped_guide_x_times = localStorage.getItem('swiped_guide_x_times');
          if(swiped_guide_x_times) {
            swiped_guide_x_times = parseInt(localStorage.getItem('swiped_guide_x_times')) + 1;
          } else {
            swiped_guide_x_times = 0;
          }
          localStorage.setItem('swiped_guide_x_times', swiped_guide_x_times);
        }
        document.querySelectorAll(".search-popup-result-carousel-item").forEach(node => {
          node.style.removeProperty('transform');
        });
        document.querySelectorAll(".search-popup-result-carousel-item").forEach(node => {
          node.style.transition = 'left .2s, transform .2s';
          node.classList.remove('active');
          node.classList.remove('second');
          node.classList.remove('third');
          node.classList.remove('second-prev');
          node.classList.remove('third-prev');
        });
        document.querySelectorAll(".search-popup-bottom-dot").forEach(node => {
          node.classList.remove('active');
        });
        event.target.closest('.search-popup-bottom-dot').classList.add('active');

        if(parseInt(window.searchResultItems.length) !== parseInt(event.target.closest('.search-popup-bottom-dot').getAttribute('data-index'))){
          document.querySelector('.search-relevant-text-box.active').classList.add('fadeOut');
          setTimeout(function(){
            document.querySelector('.search-relevant-text-box.active').classList.remove('fadeOut');
            document.querySelector('.search-relevant-text-box.active').classList.remove('active');
          }, 300);
          document.querySelector(".search-relevant-text-box[data-index='"+event.target.closest('.search-popup-bottom-dot').getAttribute('data-index')+"']").classList.add('fadeIn');

          setTimeout(function(){
            document.querySelector(".search-relevant-text-box[data-index='"+event.target.closest('.search-popup-bottom-dot').getAttribute('data-index')+"']").classList.remove('fadeIn');
            document.querySelector(".search-relevant-text-box[data-index='"+event.target.closest('.search-popup-bottom-dot').getAttribute('data-index')+"']").classList.add('active');
          }, 300);
          document.querySelector('.search-relevant-text-box-wrapper').classList.remove('last-one-result');
        } else {
          document.querySelector('.search-relevant-text-box-wrapper').classList.add('last-one-result');
        }

        if(document.querySelectorAll(".search-popup-result-carousel-item[data-index='"+event.target.closest('.search-popup-bottom-dot').getAttribute('data-index')+"']")[0]){
          document.querySelectorAll(".search-popup-result-carousel-item[data-index='"+event.target.closest('.search-popup-bottom-dot').getAttribute('data-index')+"']")[0].classList.add('active');
        }
        if(document.querySelectorAll(".search-popup-result-carousel-item[data-index='"+event.target.closest('.search-popup-bottom-dot').getAttribute('data-index')+"']")[0]){

          if( document.querySelectorAll(".search-popup-result-carousel-item[data-index='"+event.target.closest('.search-popup-bottom-dot').getAttribute('data-index')+"']")[0].previousElementSibling !== null){
            document.querySelectorAll(".search-popup-result-carousel-item[data-index='"+event.target.closest('.search-popup-bottom-dot').getAttribute('data-index')+"']")[0].previousElementSibling.classList.add('second-prev');
          }
        }
        if(document.querySelectorAll(".search-popup-result-carousel-item[data-index='"+event.target.closest('.search-popup-bottom-dot').getAttribute('data-index')+"']")[0]){
          if(document.querySelectorAll(".search-popup-result-carousel-item[data-index='"+event.target.closest('.search-popup-bottom-dot').getAttribute('data-index')+"']")[0].previousElementSibling !== null && document.querySelectorAll(".search-popup-result-carousel-item[data-index='"+event.target.closest('.search-popup-bottom-dot').getAttribute('data-index')+"']")[0].previousElementSibling.previousElementSibling !== null){
            document.querySelectorAll(".search-popup-result-carousel-item[data-index='"+event.target.closest('.search-popup-bottom-dot').getAttribute('data-index')+"']")[0].previousElementSibling.previousElementSibling.classList.add('third-prev');
          }
        }

        if(document.querySelectorAll(".search-popup-result-carousel-item[data-index='"+event.target.closest('.search-popup-bottom-dot').getAttribute('data-index')+"']")[0]){
          if( document.querySelectorAll(".search-popup-result-carousel-item[data-index='"+event.target.closest('.search-popup-bottom-dot').getAttribute('data-index')+"']")[0].nextElementSibling !== null){
            document.querySelectorAll(".search-popup-result-carousel-item[data-index='"+event.target.closest('.search-popup-bottom-dot').getAttribute('data-index')+"']")[0].nextElementSibling.classList.add('second');
          }
        }
        if(document.querySelectorAll(".search-popup-result-carousel-item[data-index='"+event.target.closest('.search-popup-bottom-dot').getAttribute('data-index')+"']")[0]){
          if(document.querySelectorAll(".search-popup-result-carousel-item[data-index='"+event.target.closest('.search-popup-bottom-dot').getAttribute('data-index')+"']")[0].nextElementSibling !== null && document.querySelectorAll(".search-popup-result-carousel-item[data-index='"+event.target.closest('.search-popup-bottom-dot').getAttribute('data-index')+"']")[0].nextElementSibling.nextElementSibling !== null){
            document.querySelectorAll(".search-popup-result-carousel-item[data-index='"+event.target.closest('.search-popup-bottom-dot').getAttribute('data-index')+"']")[0].nextElementSibling.nextElementSibling.classList.add('third');
          }
        }
      }
    });

    document.addEventListener('keyup', function (e) {
      if(window.searchOpened && document.querySelector('.search-popup-bottom-dot.active')) {
        if(e.keyCode === 39) {
          // right
          if(document.querySelector('.search-popup-bottom-dot.active').nextElementSibling !== undefined) {
            document.querySelector('.search-popup-bottom-dot.active').nextElementSibling.click();
          }
        }
        if(e.keyCode === 37) {
          // left
          if(document.querySelector('.search-popup-bottom-dot.active').previousElementSibling !== undefined){
            document.querySelector('.search-popup-bottom-dot.active').previousElementSibling.click();
          }
        }
      }
    });

    function simulateClick(elem) {
      setTimeout(function(){
        // Create our event (with options)
        var evt = new MouseEvent('click', {
            bubbles: true,
            cancelable: true,
            view: window
        });
        // If cancelled, don't dispatch our event
        var canceled = !elem.dispatchEvent(evt);
        if(canceled) {}
      }, 50);
    };

    function resizeForText(element, value) {
        var text;
        text = value;
        if (!element.value.trim()) {
          text = element.getAttribute('placeholder').trim();
        }
        var span = element.parentNode.getElementsByTagName('span')[0];
        span.textContent = text;
        var inputSize = span.offsetWidth+6;
        element.style.width = ''+inputSize+'px';
    }

    function updateSearchKeys(keys) {
      var searchTagBoxes = document.querySelectorAll('.search-tag-box');
      Object.keys(keys).forEach(function (item) {
        if(keys[item].type === 'color' && keys[item].key !== 'color' && keys[item].key !== 'colors') {
          searchTagBoxes[item].classList.add('color-type');
          var stripHashFromHex = keys[item].key.replace(/#/g, "");
          var re = /[0-9A-Fa-f]{6}/g;

          if(re.test(stripHashFromHex)) {
            // if color is hex
            searchTagBoxes[item].querySelector('.search-key-type-info-div').style.background = '#'+stripHashFromHex;
          } else {
            // if color is word
            searchTagBoxes[item].querySelector('.search-key-type-info-div').style.background = keys[item].key;
          }
        }
      });
    }
    function resetSearchData() {
      isSearching = false;
      window.searchResultItems = [];
      reactThis.setState({
        searchResultItems: [],
        firstItem: [{title: ''}],
      });
      window.resultsLoaded = false;
      document.body.classList.remove('search-result-box-loaded');
      document.querySelector('.js-search-core').classList.remove('first-animation-step');
      document.querySelector('.js-search-core').classList.remove('second-animation-step');
      document.querySelector('.js-search-core').classList.remove('second-animation-step-sub-step-1');
      document.querySelector('.js-search-core').classList.remove('second-animation-step-sub-step-2');
      document.querySelector('.js-search-core').classList.remove('third-animation-step');
      document.querySelector('.js-search-core').classList.remove('fourth-animation-step');
      document.querySelector('.js-search-core').classList.remove('fifth-animation-step');
      document.querySelector('.js-search-core').classList.remove('sixth-animation-step');
      document.querySelector('.js-search-core').classList.remove('third-animation-step-a');
      document.querySelector('.js-search-core').classList.remove('third-animation-step-b');
      document.querySelector('.js-search-core').classList.remove('third-animation-step-c');
      document.querySelector('.js-search-core').classList.remove('third-animation-step-d');
      document.querySelector('.js-search-core').classList.remove('maintain-search-height');
      searchPopupFormTagHintEnter.classList.remove('hide-search-hand');
      mainSearchInput.setAttribute('placeholder', 'What are you looking for?')
      document.querySelectorAll('.search-popup-form-heading')[0].textContent = 'What are you looking for?';

      document.getElementsByClassName('js-search-popup-form-tag-clear-enter')[0].style.opacity = '0';
      searchPopupFormTagHintEnter.classList.remove('no-results-found');

      document.querySelectorAll('.search-popup-result-carousel-item-intro-swipe-guide')[0].style.left = '0px';
      document.querySelectorAll('.search-popup-result-carousel-item-intro-swipe-guide')[0].style.removeProperty("opacity");

      setTimeout(function(){
        mainSearchInput.focus({preventScroll: true});
      }, 200);
    }

    function createSearchTag(val, searchBox) {
      val = val.replace(/"/g, "&quot;");
      var newSearchBox = document.createElement('div');
      newSearchBox.classList.add('search-tag-box');
      newSearchBox.classList.add('js-search-tag-box');
      newSearchBox.innerHTML = '<div class="search-key-type-info-div"></div><div class="search-tag-box-bg"></div><div class="resizing-input js-resizing-input"><input class="search-data-input" disabled placeholder="'+val+'" value="'+val+'" /><span style=""></span></div><div class="search-tag-box-delete"><svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="10" height="10" rx="5" fill="#15161B"/></svg></div>';
      searchBox[0].getElementsByClassName('main-search-box-div')[0].before(newSearchBox);
    }

    var startX;
    var startY;
    var x;
    var y;

    // drag search window
    var searchHeaderDiv = document.querySelectorAll('.search-popup-frame-header');
    searchHeaderDiv[0].addEventListener("mousedown", function(event) {
        startX = event.clientX - searchHeaderDiv[0].parentNode.closest('.search-popup').offsetLeft;
        startY = event.clientY - searchHeaderDiv[0].parentNode.closest('.search-popup').offsetTop;
        // Add the listeners
        document.addEventListener('mousemove', mouseMove0b);
        document.addEventListener('mouseup', mouseUp0b);
        window.searchSwiping = true;
        document.body.classList.add('is-search-swiping');
    });

    function mouseMove0b(event) {
        y = event.clientY - startY;
        x = event.clientX - startX;
        searchHeaderDiv[0].parentNode.closest('.search-popup').style.left = x + "px";
        searchHeaderDiv[0].parentNode.closest('.search-popup').style.top = y + "px";
    };

    function mouseUp0b(event) {
      window.searchSwiping = false;
      document.body.classList.remove('is-search-swiping');
      document.removeEventListener('mousemove', mouseMove0b);
      document.removeEventListener('mouseup', mouseUp0b);
    };
    // drag search window end

    searchSwipable();

    function searchSwipable() {
      var swipeWrapper = document.querySelector('.search-popup-result-carousel-items');
      var elems2 = document.querySelectorAll('.search-popup-result-carousel-item-intro-swipe-guide');

      var startX = 0;
      var startY = 0;
      var x      = 0;

      elems2[0].addEventListener("mousedown", function(event) {
        if(elems2[0].classList.contains('active')){
          startX = event.clientX - elems2[0].offsetLeft;
          startY = event.clientY - elems2[0].offsetTop;
          // Add the listeners
          document.addEventListener('mousemove', mouseMove0);
          document.addEventListener('mouseup', mouseUp0);
          window.searchSwiping = true;
          document.body.classList.add('is-search-swiping');
        }
      });
      elems2[0].addEventListener("touchstart", function(event) {
        if(elems2[0].classList.contains('active')){
          startX = event.touches[0].clientX - elems2[0].offsetLeft;
          startY = event.touches[0].clientY - elems2[0].offsetTop;
          // Add the listeners
          document.addEventListener('touchmove', touchMove0);
          document.addEventListener('touchend', touchUp0);
          window.searchSwiping = true;
          document.body.classList.add('is-search-swiping');
        }
      });

      function mouseMove0(event) {
        if(elems2[0].classList.contains('active')){
          y = event.clientY - startY;
          x = event.clientX - startX;
          elems2[0].style.left = x + "px";
        }
      };

      function touchMove0(event) {
        if(elems2[0].classList.contains('active')){
          y = event.touches[0].clientY - startY;
          x = event.touches[0].clientX - startX;
          elems2[0].style.left = x + "px";
        }
      };

      function touchUp0 (event) {
        var swiped_guide_x_times = localStorage.getItem('swiped_guide_x_times');
        if(elems2[0].classList.contains('active')){
          setTimeout(function(){
            window.searchSwiping = false;
            document.body.classList.remove('is-search-swiping');
          }, 200);

          elems2[0].style.left = 0 + "px";

          if(x < -80) {
            elems2[0].style.left = '-120%';

            if(swiped_guide_x_times) {
              swiped_guide_x_times = parseInt(localStorage.getItem('swiped_guide_x_times')) + 1;
            } else {
              swiped_guide_x_times = 0;
            }

            localStorage.setItem('swiped_guide_x_times', swiped_guide_x_times);
            document.body.classList.remove('howtoguideopened');
          }
          else if(x > 80) {
            elems2[0].style.left = '120%';

            if(swiped_guide_x_times) {
              swiped_guide_x_times = parseInt(localStorage.getItem('swiped_guide_x_times')) + 1;
            } else {
              swiped_guide_x_times = 0;
            }

            localStorage.setItem('swiped_guide_x_times', swiped_guide_x_times);
            document.body.classList.remove('howtoguideopened');
          }
          // Remove the listeners
          document.removeEventListener('touchmove', touchMove0);
          document.removeEventListener('touchend', touchUp0);
        }
      }

      function mouseUp0 (event) {
        var swiped_guide_x_times = localStorage.getItem('swiped_guide_x_times');
        if(elems2[0].classList.contains('active')){
          setTimeout(function(){
            window.searchSwiping = false;
            document.body.classList.remove('is-search-swiping');
          }, 200);

          elems2[0].style.left = 0 + "px";

          if(x < -80) {
            elems2[0].style.left = '-120%';

            if(swiped_guide_x_times) {
              swiped_guide_x_times = parseInt(localStorage.getItem('swiped_guide_x_times')) + 1;
            } else {
              swiped_guide_x_times = 0;
            }

            localStorage.setItem('swiped_guide_x_times', swiped_guide_x_times);
            document.body.classList.remove('howtoguideopened');
          }
          else if(x > 80) {
            elems2[0].style.left = '120%';

            if(swiped_guide_x_times) {
              swiped_guide_x_times = parseInt(localStorage.getItem('swiped_guide_x_times')) + 1;
            } else {
              swiped_guide_x_times = 0;
            }

            localStorage.setItem('swiped_guide_x_times', swiped_guide_x_times);
            document.body.classList.remove('howtoguideopened');
          }
          // Remove the listeners
          document.removeEventListener('mousemove', mouseMove0);
          document.removeEventListener('mouseup', mouseUp0);
        }
      };

      swipeWrapper.addEventListener("mousedown", function(event) {

        var elem = document.querySelector('.search-popup-result-carousel-item.active');
        if(elem && !event.target.closest('.search-jump-to-unrelevant-btn') && !event.target.closest('.search-jump-to-btn') && event.which === 1) {
          let bbox_rect = swipeWrapper.getBoundingClientRect();
          let layerX = event.clientX-bbox_rect.left;
          if(layerX > 12 && layerX < 615 ) {
            if(elem.classList.contains('active')){
              elem.classList.add('current-swipeable-elem');
              startX = event.clientX - elem.offsetLeft;
              startY = event.clientY - elem.offsetTop;

              // Add the listeners
              document.addEventListener('mousemove', mouseMove);
              document.addEventListener('mouseup', mouseUp);
              window.searchSwiping = true;

              document.body.classList.add('is-search-swiping');
            }
          } else {
            if (event.target.closest('.second-prev')) {
              simulateClick(document.querySelectorAll(".search-popup-bottom-dot[data-index='"+event.target.closest('.second-prev').getAttribute('data-index')+"']")[0]);

            } else if (event.target.closest('.third-prev')) {
              simulateClick(document.querySelectorAll(".search-popup-bottom-dot[data-index='"+event.target.closest('.third-prev').getAttribute('data-index')+"']")[0]);

            } else if (event.target.closest('.second')) {
              simulateClick(document.querySelectorAll(".search-popup-bottom-dot[data-index='"+event.target.closest('.second').getAttribute('data-index')+"']")[0]);

            } else if (event.target.closest('.third')) {
              simulateClick(document.querySelectorAll(".search-popup-bottom-dot[data-index='"+event.target.closest('.third').getAttribute('data-index')+"']")[0]);
            }
          }
        }
      });


      swipeWrapper.addEventListener("touchstart", function(event) {
        var elem = document.querySelector('.search-popup-result-carousel-item.active');
        if(elem && !event.target.closest('.search-jump-to-unrelevant-btn') && !event.target.closest('.search-jump-to-btn')) {
          let bbox_rect = swipeWrapper.getBoundingClientRect();
          let layerX = event.touches[0].clientX-bbox_rect.left;

          if(layerX > 12 && layerX < 615 ) {
            if(elem.classList.contains('active')){
              elem.classList.add('current-swipeable-elem');
              startX = event.touches[0].clientX - elem.offsetLeft;
              startY = event.touches[0].clientY - elem.offsetTop;
              // Add the listeners
              document.addEventListener('touchmove', touchMove);
              document.addEventListener('touchend', touchUp);
              window.searchSwiping = true;

              document.body.classList.add('is-search-swiping');
            }
          } else {
            if (event.target.closest('.second-prev')) {
              simulateClick(document.querySelectorAll(".search-popup-bottom-dot[data-index='"+event.target.closest('.second-prev').getAttribute('data-index')+"']")[0]);

            } else if (event.target.closest('.third-prev')) {
              simulateClick(document.querySelectorAll(".search-popup-bottom-dot[data-index='"+event.target.closest('.third-prev').getAttribute('data-index')+"']")[0]);

            } else if (event.target.closest('.second')) {
              simulateClick(document.querySelectorAll(".search-popup-bottom-dot[data-index='"+event.target.closest('.second').getAttribute('data-index')+"']")[0]);

            } else if (event.target.closest('.third')) {
              simulateClick(document.querySelectorAll(".search-popup-bottom-dot[data-index='"+event.target.closest('.third').getAttribute('data-index')+"']")[0]);
            }
          }
        }
      });

      var isSafari = window.safari !== undefined;
      if (!isSafari){
        var transitionFlag = false;
        swipeWrapper.addEventListener("mouseenter", (evt) => {
          if(!document.body.classList.contains('howtoguideopened') && !document.body.classList.contains('unrelevant-view-called')) {
            const card = document.querySelector('.search-popup-result-carousel-item.active');
            if(card){
              card.style.setProperty("transition", "all .1s", "important");
              setTimeout(function(){
                transitionFlag = true;
                card.style.removeProperty("transition");
              }, 100);
            }
          }
        });

        swipeWrapper.addEventListener("mousemove", (evt) => {
          if(!document.body.classList.contains('howtoguideopened') && !document.body.classList.contains('unrelevant-view-called')) {
            const card = document.querySelector('.search-popup-result-carousel-item.active');
            if(card){
              let bbox_rect = swipeWrapper.getBoundingClientRect();
              let layerX = evt.clientX-bbox_rect.left;
              const cardRect = card.getBoundingClientRect();
              if(layerX > 0 && layerX < 624 ) {

                const x = (evt.clientX - cardRect.left) / cardRect.width;
                const dx = -(x - 0.5);
                const dxNorm = Math.min(Math.max(dx, -0.5), 0.5);
                const y = (evt.clientY - cardRect.top) / cardRect.height - 0.5;
                if(transitionFlag){
                  card.style.removeProperty("transition");
                  transitionFlag = false;
                }
                card.style.transform = `perspective(1500px) rotateY(${
                  -dxNorm * 15
                }deg) rotateX(${-y * 15}deg) `;
              } else {

                card.style.transform = "none";
                card.style.setProperty("transition", "all .1s", "important");
                setTimeout(function(){
                  transitionFlag = true;
                  card.style.removeProperty("transition");
                }, 100);
              }
            }
          }
        });
        swipeWrapper.addEventListener("mouseleave", (evt) => {
          if(!document.body.classList.contains('howtoguideopened') && !document.body.classList.contains('unrelevant-view-called')) {
            const card = document.querySelector('.search-popup-result-carousel-item.active');
            if(card){
              card.style.transform = "none";
              card.style.setProperty("transition", "all .1s", "important");
              setTimeout(function(){
                transitionFlag = true;
                card.style.removeProperty("transition");
              }, 100);
            }
          }
        });
      }

      function mouseMove(event) {

        var elem = document.querySelector('.search-popup-result-carousel-item.active');
        var elems = document.querySelectorAll('.search-popup-result-carousel-item');
        if(elem){
          if(elem.classList.contains('active')){
            y = event.clientY - startY;
            x = event.clientX - startX;
            if(parseInt(elem.getAttribute('data-index')) === 0) {
              if(x > 0) {
                x = 0;
              }
            }
            if(parseInt(elem.getAttribute('data-index')) === parseInt(elems.length-1)) {
              if(x < 0) {
                x = 0;
              }
            }

            if(x < 0) {
              if(parseInt(elem.getAttribute('data-index')) !== parseInt(elems.length-1)) {
                document.body.classList.add('js-search-left-swiping');
                document.body.classList.remove('js-search-right-swiping');
                if(elem.nextElementSibling !== undefined){
                  elem.nextElementSibling.classList.add('sneak-peak');
                }
              }
            } else {
              if(parseInt(elem.getAttribute('data-index')) !== 0) {
                document.body.classList.add('js-search-right-swiping');
                document.body.classList.remove('js-search-left-swiping')
                if(elem.previousElementSibling !== undefined){
                  elem.previousElementSibling.classList.add('sneak-peak');
                }
              }
            }
            elem.style.left = x + "px";
            elem.style.transform = "none";
          }
        }
      };

      function touchMove(event) {
        var elem = document.querySelector('.search-popup-result-carousel-item.active');
        var elems = document.querySelectorAll('.search-popup-result-carousel-item');
        if(elem){
          if(elem.classList.contains('active')){
            y = event.touches[0].clientY - startY;
            x = event.touches[0].clientX - startX;
            if(parseInt(elem.getAttribute('data-index')) === 0) {
              if(x > 0) {
                x = 0;
              }
            }
            if(parseInt(elem.getAttribute('data-index')) === parseInt(elems.length-1)) {
              if(x < 0) {
                x = 0;
              }
            }
            if(x < 0) {
              if(parseInt(elem.getAttribute('data-index')) !== parseInt(elems.length-1)) {
                document.body.classList.add('js-search-left-swiping');
                document.body.classList.remove('js-search-right-swiping');
                if(elem.nextElementSibling !== undefined){
                  elem.nextElementSibling.classList.add('sneak-peak');
                }
              }
            } else {
              if(parseInt(elem.getAttribute('data-index')) !== 0) {
                document.body.classList.add('js-search-right-swiping');
                document.body.classList.remove('js-search-left-swiping')
                if(elem.previousElementSibling !== undefined){
                  elem.previousElementSibling.classList.add('sneak-peak');
                }
              }
            }
            elem.style.left = x + "px";
            elem.style.transform = "none";
          }
        }
      };

      function touchUp (event) {
        var elem = document.querySelector('.search-popup-result-carousel-item.active');
        if(elem) {
          if(elem.classList.contains('active')){
            elem.style.transition = 'left .4s, transform .4s';
            elem.classList.add('flashback');
            setTimeout(function(){
              window.searchSwiping = false;
              document.body.classList.remove('is-search-swiping');
            }, 200);
            if(x < 0) {
              elem.style.left = -35 + "px";
            } else {
              elem.style.left = 35 + "px";
            }
            setTimeout(function(){
              elem.style.removeProperty("left");
            }, 400);
            setTimeout(function(){
              elem.classList.remove('flashback');
            }, 1200);
            document.querySelectorAll(".search-popup-result-carousel-item").forEach(node => {
              node.classList.remove('sneak-peak');
              node.classList.remove('current-swipeable-elem');

            });
            setTimeout(function(){
              document.querySelectorAll(".search-popup-result-carousel-item").forEach(node => {
                node.style.removeProperty('transform');
              });
            }, 400);

            if(x < -80) {
              if(elem.nextElementSibling) {
                elem.classList.remove('active');
                elem.classList.add('second-prev');
                if(elem.previousElementSibling) {
                  elem.previousElementSibling.classList.remove('second');
                  elem.previousElementSibling.classList.add('third-prev');
                }
                elem.nextElementSibling.classList.remove('second');
                elem.nextElementSibling.classList.add('active');
                if(elem.nextElementSibling.nextElementSibling) {
                  elem.nextElementSibling.nextElementSibling.classList.remove('third');
                  elem.nextElementSibling.nextElementSibling.classList.add('second');
                  if(elem.nextElementSibling.nextElementSibling.nextElementSibling) {
                    elem.nextElementSibling.nextElementSibling.nextElementSibling.classList.add('third');
                  }
                }
              }
            }
            else if(x > 80) {
              if(elem.previousElementSibling) {
                elem.classList.remove('active');
                elem.classList.add('second');
                if(elem.nextElementSibling) {
                  elem.nextElementSibling.classList.remove('second');
                  elem.nextElementSibling.classList.add('third');
                  if(elem.nextElementSibling.nextElementSibling) {
                    elem.nextElementSibling.nextElementSibling.classList.remove('third');
                  }
                }
                elem.previousElementSibling.classList.remove('second-prev');
                elem.previousElementSibling.classList.add('active');
                if(elem.previousElementSibling.previousElementSibling) {
                  elem.previousElementSibling.previousElementSibling.classList.remove('third-prev');
                  elem.previousElementSibling.previousElementSibling.classList.add('second-prev');
                  if(elem.previousElementSibling.previousElementSibling.previousElementSibling) {
                    elem.previousElementSibling.previousElementSibling.previousElementSibling.classList.add('third-prev');
                  }
                }
              }
            }

            x = 0;
            document.querySelectorAll(".search-popup-bottom-dot").forEach(node => {
              node.classList.remove('active');
            });
            var newActivelem = document.querySelector('.search-popup-result-carousel-item.active');
            if(newActivelem){
              setTimeout(function(){
                document.querySelector(".search-popup-bottom-dot[data-index='"+newActivelem.getAttribute('data-index')+"']").classList.add('active');
              }, 50);
            }
            if(parseInt(window.searchResultItems.length) !== parseInt(newActivelem.getAttribute('data-index'))) {
              document.querySelector('.search-relevant-text-box.active').classList.add('fadeOut');
              setTimeout(function(){
                document.querySelector('.search-relevant-text-box.active').classList.remove('fadeOut');
                document.querySelector('.search-relevant-text-box.active').classList.remove('active');
              }, 300);
              document.querySelector(".search-relevant-text-box[data-index='"+newActivelem.getAttribute('data-index')+"']").classList.add('fadeIn');
              setTimeout(function(){
                document.querySelector(".search-relevant-text-box[data-index='"+newActivelem.getAttribute('data-index')+"']").classList.remove('fadeIn');
                document.querySelector(".search-relevant-text-box[data-index='"+newActivelem.getAttribute('data-index')+"']").classList.add('active');
              }, 300);
              document.querySelector('.search-relevant-text-box-wrapper').classList.remove('last-one-result');
            } else {
              document.querySelector('.search-relevant-text-box-wrapper').classList.add('last-one-result');
            }
            // Remove the listeners
            document.removeEventListener('touchmove', touchMove);
            document.removeEventListener('touchend', touchUp);
          }
        }
      };

      function mouseUp (event) {
        var elem = document.querySelector('.search-popup-result-carousel-item.active');
        if(elem) {
          if(elem.classList.contains('active')){
            elem.style.transition = 'left .4s, transform .4s';
            elem.classList.add('flashback');
            setTimeout(function(){
              window.searchSwiping = false;
              document.body.classList.remove('is-search-swiping');
            }, 200);
            if(x < 0) {
              elem.style.left = -35 + "px";
            } else {
              elem.style.left = 35 + "px";
            }
            setTimeout(function(){
              elem.style.removeProperty("left");
            }, 400);
            setTimeout(function(){
              elem.classList.remove('flashback');
            }, 1200);
            document.querySelectorAll(".search-popup-result-carousel-item").forEach(node => {
              node.classList.remove('sneak-peak');
              node.classList.remove('current-swipeable-elem');

            });
            setTimeout(function(){
              document.querySelectorAll(".search-popup-result-carousel-item").forEach(node => {
                node.style.removeProperty('transform');
              });
            }, 400);

            if(x < -80) {
              if(elem.nextElementSibling) {
                elem.classList.remove('active');
                elem.classList.add('second-prev');
                if(elem.previousElementSibling) {
                  elem.previousElementSibling.classList.remove('second');
                  elem.previousElementSibling.classList.add('third-prev');
                }
                elem.nextElementSibling.classList.remove('second');
                elem.nextElementSibling.classList.add('active');
                if(elem.nextElementSibling.nextElementSibling) {
                  elem.nextElementSibling.nextElementSibling.classList.remove('third');
                  elem.nextElementSibling.nextElementSibling.classList.add('second');
                  if(elem.nextElementSibling.nextElementSibling.nextElementSibling) {
                    elem.nextElementSibling.nextElementSibling.nextElementSibling.classList.add('third');
                  }
                }
              }
            }
            else if(x > 80) {
              if(elem.previousElementSibling) {
                elem.classList.remove('active');
                elem.classList.add('second');
                if(elem.nextElementSibling) {
                  elem.nextElementSibling.classList.remove('second');
                  elem.nextElementSibling.classList.add('third');
                  if(elem.nextElementSibling.nextElementSibling) {
                    elem.nextElementSibling.nextElementSibling.classList.remove('third');
                  }
                }
                elem.previousElementSibling.classList.remove('second-prev');
                elem.previousElementSibling.classList.add('active');
                if(elem.previousElementSibling.previousElementSibling) {
                  elem.previousElementSibling.previousElementSibling.classList.remove('third-prev');
                  elem.previousElementSibling.previousElementSibling.classList.add('second-prev');
                  if(elem.previousElementSibling.previousElementSibling.previousElementSibling) {
                    elem.previousElementSibling.previousElementSibling.previousElementSibling.classList.add('third-prev');
                  }
                }
              }
            }

            x = 0;
            document.querySelectorAll(".search-popup-bottom-dot").forEach(node => {
              node.classList.remove('active');
            });
            var newActivelem = document.querySelector('.search-popup-result-carousel-item.active');
            if(newActivelem){
              setTimeout(function(){
                document.querySelector(".search-popup-bottom-dot[data-index='"+newActivelem.getAttribute('data-index')+"']").classList.add('active');
              }, 50);
            }
            if(parseInt(window.searchResultItems.length) !== parseInt(newActivelem.getAttribute('data-index'))) {
              document.querySelector('.search-relevant-text-box.active').classList.add('fadeOut');
              setTimeout(function(){
                document.querySelector('.search-relevant-text-box.active').classList.remove('fadeOut');
                document.querySelector('.search-relevant-text-box.active').classList.remove('active');
              }, 300);
              document.querySelector(".search-relevant-text-box[data-index='"+newActivelem.getAttribute('data-index')+"']").classList.add('fadeIn');
              setTimeout(function(){
                document.querySelector(".search-relevant-text-box[data-index='"+newActivelem.getAttribute('data-index')+"']").classList.remove('fadeIn');
                document.querySelector(".search-relevant-text-box[data-index='"+newActivelem.getAttribute('data-index')+"']").classList.add('active');
              }, 300);
              document.querySelector('.search-relevant-text-box-wrapper').classList.remove('last-one-result');
            } else {
              document.querySelector('.search-relevant-text-box-wrapper').classList.add('last-one-result');
            }
            // Remove the listeners
            document.removeEventListener('mousemove', mouseMove);
            document.removeEventListener('mouseup', mouseUp);
          }
        }
      };
    }

    function generateSearchData() {
      if(!isSearching){
        isSearching = true;
        document.body.classList.add('loadingsearch')
        if(document.querySelectorAll('.search-tag-box .js-resizing-input').length === 0) {
          document.querySelector('.js-search-core').classList.remove('maintain-search-height');
          resetSearchData();
          return false;
        }

        document.querySelector('.search-popup-form-loader-bar-border').style.removeProperty('transition');
        document.querySelector('.search-popup-form-loader-bar-border').style.removeProperty('width');

        var swiped_guide_x_times = localStorage.getItem('swiped_guide_x_times');
        if(swiped_guide_x_times > 1) {
          document.querySelector('.search-popup-result-carousel-item-intro-swipe-guide').style.display = 'none';
          document.body.classList.remove('howtoguideopened');
        } else {
          document.body.classList.add('howtoguideopened');
        }

        document.querySelector('.search-popup-result-carousel-item-intro-swipe-guide').style.removeProperty('left');
        document.querySelector('.js-search-core').classList.remove('third-animation-step');
        document.querySelector('.js-search-core').classList.remove('fourth-animation-step');
        document.querySelector('.js-search-core').classList.remove('fifth-animation-step');
        document.querySelector('.js-search-core').classList.remove('sixth-animation-step');
        document.querySelector('.js-search-core').classList.remove('third-animation-step-a');
        document.querySelector('.js-search-core').classList.remove('third-animation-step-b');
        document.querySelector('.js-search-core').classList.remove('third-animation-step-c');
        document.querySelector('.js-search-core').classList.remove('third-animation-step-d');

        window.searchResultItems = [];
        reactThis.setState({
          searchResultItems: [],
          firstItem: [{title: ''}],
        });
        window.resultsLoaded = false;
        document.body.classList.remove('search-result-box-loaded')
        document.querySelector('.js-search-core').classList.remove('first-animation-step');
        document.querySelector('.js-search-core').classList.remove('second-animation-step');
        document.querySelector('.js-search-core').classList.remove('second-animation-step-sub-step-1');
        document.querySelector('.js-search-core').classList.remove('second-animation-step-sub-step-2');
        document.querySelector('.js-search-core').classList.add('first-animation-step');
        document.querySelector('.js-search-core').classList.add('maintain-search-height');


        var searchString = '';
        var counter = 1;
        document.querySelectorAll('.search-data-input').forEach(el => {
          if(counter !== document.querySelectorAll('.search-data-input').length) {
            searchString += ''+el.value+',';
          } else {
            searchString += ''+el.value+'';
          }
          counter++;
        });

        var replacedSearchString = searchString;

        setTimeout(function(){
          document.querySelector('.js-search-core').classList.add('second-animation-step');
          document.body.classList.add('search-result-box-loaded')

          axios.post(window.EDITOR_URL + '/brand/search', {
            keys: replacedSearchString,
            brand: window.loadedBrandHash
          },{
            headers: {
              Pragma: 'no-cache',
              Authorization: localStorage.getItem('authToken') || ''
            }
          }).then(function (response) {
            window.resultsLoaded = true;

            Object.keys(response.data.results).forEach(function(key) {
              if(response.data.results[key].type !==  'page' && response.data.results[key].type !== 'masthead') {
                response.data.results[key].modules = [response.data.results[key].prev_next_modules.prev, response.data.results[key].module, response.data.results[key].prev_next_modules.next];
              }
            });

            window.searchResultItems = response.data.results;

            if(response.data.results[0]){
              reactThis.setState({
                searchResultItems: response.data.results,
                firstItem: response.data.results[0]
              });
            } else {
              reactThis.setState({
                searchResultItems: [],
                firstItem: [{title: ''}]
              });
            }

            window.searchResultItems.forEach((el, index) => {
              var appendstring;
              if(index !== 0){
                var random = Math.floor(Math.random() * resultsRelevantPlaceholderTexts.length);
                appendstring = '<div>'+resultsRelevantPlaceholderTexts[random].replace('"textvarchange"', '"<a href="'+window.searchResultItems[index].public+'" ng-click="toggleSearchPopup(); jumpToSection('+window.searchResultItems[index].id+');" class="js-go-to-result">'+window.searchResultItems[index].title+'</a>"')+'</div>';
              } else {
                appendstring = 'The most relevant result is located in the "<a href="'+window.searchResultItems[index].public+'" ng-click="toggleSearchPopup(); jumpToSection(item.id);" class="js-go-to-result">'+window.searchResultItems[index].title+'</a>" section!';
              }

              var relevantBoxDiv = document.querySelectorAll('.search-relevant-text-box[data-index="'+index+'"] .html_link');
              relevantBoxDiv[0].innerHTML = appendstring;
            });

            updateSearchKeys(response.data.keys);

            setTimeout(function(){
              if(response.data.results.length === 0) {
                searchPopupFormTagHintEnter.classList.add('no-results-found');
                document.querySelector('.search-popup-bottom-dots').classList.add('no-dots');
                //no results view
                document.querySelector('.js-search-core').classList.add('third-animation-step');
                setTimeout(function(){
                  document.querySelector('.js-search-core').classList.add('third-animation-step-a');
                }, 200);
                setTimeout(function(){
                  document.querySelector('.js-search-core').classList.add('third-animation-step-b');
                }, 600);
                setTimeout(function(){
                  document.querySelector('.js-search-core').classList.add('third-animation-step-c');
                }, 1000);
                setTimeout(function(){
                  document.querySelector('.js-search-core').classList.add('third-animation-step-d');
                }, 1100);
                setTimeout(function(){
                  document.querySelector('.js-search-core').classList.add('third-animation-step-d');
                }, 1200);
                setTimeout(function(){
                  document.body.classList.remove('loadingsearch')
                  isSearching = false;
                  document.querySelector('.search-popup-form-loader-bar-border').style.transition = 'none';
                  document.querySelector('.search-popup-form-loader-bar-border').style.width = '0px';
                  document.getElementsByClassName('js-search-popup-form-tag-clear-enter')[0].style.opacity = '0.5';
                }, 1600);
              } else {
                searchPopupFormTagHintEnter.classList.remove('no-results-found');
                document.querySelector('.search-popup-bottom-dots').classList.remove('no-dots');
                document.querySelector('.js-search-core').classList.add('third-animation-step');
                setTimeout(function(){
                  document.querySelector('.js-search-core').classList.add('third-animation-step-a');
                }, 200);
                setTimeout(function(){
                  document.querySelector('.js-search-core').classList.add('third-animation-step-b');
                }, 600);
                setTimeout(function(){
                  document.querySelector('.js-search-core').classList.add('third-animation-step-c');
                }, 1000);
                setTimeout(function(){
                  document.querySelector('.js-search-core').classList.add('third-animation-step-d');
                }, 1100);
                setTimeout(function(){
                  document.querySelector('.js-search-core').classList.add('third-animation-step-d');
                }, 1200);

                setTimeout(function(){
                  document.body.classList.remove('loadingsearch')
                  isSearching = false;
                  document.querySelector('.search-popup-form-loader-bar-border').style.transition = 'none';
                  document.querySelector('.search-popup-form-loader-bar-border').style.width = '0px';
                  document.getElementsByClassName('js-search-popup-form-tag-clear-enter')[0].style.opacity = '0';
                  document.getElementsByClassName('js-search-popup-form-tag-clear-enter')[0].style.opacity = '0.5';
                }, 1600);
                setTimeout(function(){
                  var elems =  document.querySelectorAll('.search-popup-result-carousel-item-inner');
                  elems.forEach(el => {
                      var parentElement = el;
                      var childElement = el.querySelector('.center-those-modules .module');
                      if(childElement){

                        var globalItemBlockHeightChecker = childElement.offsetHeight*0.54;
                        if(childElement.previousElementSibling){
                          globalItemBlockHeightChecker += childElement.previousElementSibling.offsetHeight*0.54;
                        }
                        if(childElement.nextElementSibling){
                          globalItemBlockHeightChecker += childElement.nextElementSibling.offsetHeight*0.54;
                        }

                        parentElement.scrollTop = (childElement.offsetTop*0.54)+1;
                        if(globalItemBlockHeightChecker < 318) {
                          //childElement.style.minHeight = 382+'px';
                          parentElement.scrollTop = 159;
                        }
                      }
                  });

                  const cards = document.querySelectorAll(".search-jump-to-btn");
                  for(let i=0; i<cards.length; i++){
                    cards[i].addEventListener('mousemove', rotate);
                    cards[i].addEventListener('mouseleave', clearRotate);
                  }

                  function rotate(e){
                    const cardItem = this.querySelector(".svg-div");
                    cardItem.style.transform = 'rotate(' + 45 + 'deg)';
                  }

                  function clearRotate(e){
                    if(e.target.classList.contains("search-jump-to-btn")){
                      const cardItem = this.querySelector(".svg-div");
                      cardItem.style.transform = 'rotate(0)';
                    }
                  }
                }, 2000);
              }
            }, 1);
          }).catch(error => {});
        }, 1);
      }
    }
  }

  render () {
    let props = this.props;
    let brand = props.brand;

    return (
      <div>
        <div className="search-core" style={{display: brand.menu_search !== 'yes' ? 'none' : ''}}>
          <div style={{marginLeft: brand.menu_padding_left+'px', marginRight: brand.menu_scrollbar === 'yes' ? parseInt(brand.menu_padding_right)-6+'px' : brand.menu_padding_right+'px',backgroundColor: this.hexToHSL(brand.menu_bg_color)}} className="trigger-search-popup js-trigger-search-popup" href="#" onClick={() => { this._toggleSearchPopup() }} onMouseOver={() => { this._searchPopupOnMouseOver() }}>
            <div className="search-trigger-icon-box"></div>
            <div className="search-trigger-icon-text-box hide-on-hover" data-font-size={ brand.menu_font_size > 30 ? '30' : brand.menu_font_size} style={{color:brand.menu_item_color, fontWeight: brand.menu_font_weight}}>Search<span className="search-trigger-icon-text-box-deggree-symbol">°</span></div>
            <div className="search-shorcut-box">
              <div className="search-shorcut-quadrant os-quadrant" style={{borderColor: this.hex2rgba(brand.menu_item_color, 1)}}>
                <span className="if-windows-cmd-quadrant" style={{color: this.hex2rgba(brand.menu_item_color, 1)}}>Ctrl</span>
                <svg className="if-mac-cmd-quadrant" width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M5.5 1.5C5.5 0.947715 5.94772 0.5 6.5 0.5C7.05228 0.5 7.5 0.947715 7.5 1.5C7.5 2.05228 7.05228 2.5 6.5 2.5H5.5V1.5Z" stroke={this.hex2rgba(brand.menu_item_color, 1)}/>
                <path d="M1.5 2.5C0.947715 2.5 0.5 2.05228 0.5 1.5C0.5 0.947715 0.947715 0.5 1.5 0.5C2.05228 0.5 2.5 0.947716 2.5 1.5L2.5 2.5L1.5 2.5Z" stroke={this.hex2rgba(brand.menu_item_color, 1)}/>
                <path d="M2.5 6.5C2.5 7.05228 2.05228 7.5 1.5 7.5C0.947715 7.5 0.5 7.05228 0.5 6.5C0.5 5.94772 0.947715 5.5 1.5 5.5L2.5 5.5L2.5 6.5Z" stroke={this.hex2rgba(brand.menu_item_color, 1)}/>
                <path d="M6.5 5.5C7.05228 5.5 7.5 5.94772 7.5 6.5C7.5 7.05228 7.05228 7.5 6.5 7.5C5.94772 7.5 5.5 7.05228 5.5 6.5L5.5 5.5L6.5 5.5Z" stroke={this.hex2rgba(brand.menu_item_color, 1)}/>
                <rect x="2.5" y="2.5" width="3" height="3" stroke={this.hex2rgba(brand.menu_item_color, 1)}/>
                </svg>
              </div>
              <div className="search-shorcut-quadrant" style={{borderColor: this.hex2rgba(brand.menu_item_color, 1)}}>
                <span style={{color: this.hex2rgba(brand.menu_item_color, 1)}}>G</span>
              </div>
            </div>
          </div>
        </div>

        <div className="search-core js-search-core">
          <div className="fixed-layer-popup"></div>
          <div className="search-popup">
            <div className="search-popup-frame">
              <div className="search-popup-frame-header">
                <span className="search-popup-frame-header-title">
                  Search the core
                </span>
                <div onClick={() => { this._closeSearchPopup() }} className="search-close-btn">
                  <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect width="11" height="11" rx="5.5" fill="#15161B"/>
                  </svg>
                </div>
              </div>
              <div className="search-popup-frame-body">
                <div className="search-popup-frame-wrapper">
                  <div className="search-popup-frame-body-title">
                    <span className="search-popup-form-heading">What are you looking for?</span>
                  </div>
                  <div className={"search-popup-form search-popup-form-items-count- "+(this.state.searchInputFocused ? '' : 'not-focused-search-input')}>

                    <div className="search-popup-form-box">
                      <svg className="search-core-search-icon" width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g opacity="0.5">
                      <path d="M14.1798 7.53409C14.1798 11.1358 11.2364 14.0682 7.5899 14.0682C3.94343 14.0682 1 11.1358 1 7.53409C1 3.93236 3.94343 1 7.5899 1C11.2364 1 14.1798 3.93236 14.1798 7.53409Z" stroke="white" strokeWidth="2"/>
                      <line y1="-1" x2="7.0085" y2="-1" transform="matrix(0.700737 0.71342 -0.71857 0.695454 12.0889 13)" stroke="white" strokeWidth="2"/>
                      </g>
                      </svg>

                      <div className="search-box-append-tags">

                        <div className="resizing-input js-resizing-input main-search-box-div inactive">
                          <input className="js-main-search-input main-search-input" type="name" placeholder="What are you looking for?" />
                          <span></span>
                        </div>
                      </div>

                      <div className="search-popup-form-tag-clear-enter js-search-popup-form-tag-clear-enter">
                        <svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M5 2L9 6" stroke="#fff"/>
                        <path d="M9 2L5 6" stroke="#fff"/>
                        <path d="M0.691223 4L4.03213 0.5H11V7.5H4.03213L0.691223 4Z" stroke="#fff"/>
                        </svg>

                        <span>Clear</span>
                      </div>

                      <div className="search-popup-form-tag-hint-enter js-search-popup-form-tag-hint-enter">
                        <svg width="11" height="7" viewBox="0 0 11 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g opacity="1">
                        <path d="M1 1V3.5H9" stroke="white"/>
                        <path d="M7 1L9.5 3.5L7 6" stroke="white"/>
                        </g>
                        </svg>
                        <span>Enter</span>
                      </div>
                    </div>
                    <div className="search-popup-form-bottom-loader-bar">
                      <div className="search-popup-form-loader-bar-border"></div>
                    </div>
                  </div>
                  <div className="search-popup-results-box">
                    <div className="search-relevant-text-box-wrapper">
                    {this.state.searchResultItems.map((searchItem, index) => {
                      return (
                      <div key={index} className={'search-relevant-text-box '+(index === 0 ? 'active' : '') } data-index={index}>
                        <div className="html_link"></div>
                      </div>
                      );
                    })}
                      <div className="search-relevant-text-box last-fake"><div></div></div>
                    </div>

                    <div className="search-popup-result-carousel">

                      <div className="results-loading">
                        <div className="loader-ring-div-1">
                          <div className="loader-ring-div-2">
                            <div className="rotating-loading-ring">
                              <img src={loadingRing} alt="" />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="search-popup-result-carousel-items">
                        {this.state.searchResultItems.map((searchItem, index) => {
                         return (
                          <div key={index} data-index={index} className={'search-popup-result-carousel-item '+(index === 0 ? 'active' : '') + (index === 1 ? 'second' : '') + (index === 2 ? 'third' : '')}>
                            <div className="search-popup-result-carousel-item-inner-overlay">
                              <div className="search-popup-result-carousel-item-inner-overlay-box"></div>
                            </div>
                              <div className="search-result-item-cta">
                              <div className="search-jump-to-btn">
                                <NavLink to={searchItem.public} className="js-go-to-result">
                                  <div className="svg-div">
                                    <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M1.30757 9.67471L9.26235 1.71993M9.73027 6.86714V1.252H4.11514" stroke="#000"/>
                                    </svg>
                                  </div>
                                  <span>Jump to context</span>
                                </NavLink>
                              </div>
                            </div>
                            <div className="search-popup-result-carousel-item-inner">
                              <div className="brand-content fade-in page-content">
                              {searchItem.type === 'masthead' &&
                                <div>

                                {searchItem.modules.map(module => {
                                if(module.module === 'carousel')
                                  return <div className={'_scroll_'+module.id} key={module.id} ref={(el) => { if (el !== null && module.id === this.state.refId) { el.scrollIntoView({ behavior: this.state.scrollBehavior }); } }}><Carousel data={module} /></div>

                                  if(module.module === 'colors')
                                  return <div className={'_scroll_'+module.id} key={module.id} ref={(el) => { if (el !== null && module.id === this.state.refId) { el.scrollIntoView({ behavior: this.state.scrollBehavior }); } }}><Colors brand={brand} data={module} /></div>

                                  if(module.module === 'downloads')
                                  return <div className={'_scroll_'+module.id} key={module.id} ref={(el) => { if (el !== null && module.id === this.state.refId) { el.scrollIntoView({ behavior: this.state.scrollBehavior }); } }}><Downloads brand={brand.link} data={module} showcase={brand.showcase} /></div>

                                  if(module.module === 'grid')
                                  return <div className={'_scroll_'+module.id} key={module.id} ref={(el) => { if (el !== null && module.id === this.state.refId) { el.scrollIntoView({ behavior: this.state.scrollBehavior }); } }}><Grid data={module} openModal={this.openModal} /></div>

                                  if(module.module === 'do.dont')
                                  return <div className={'_scroll_'+module.id} key={module.id} ref={(el) => { if (el !== null && module.id === this.state.refId) { el.scrollIntoView({ behavior: this.state.scrollBehavior }); } }}><Dodont data={module} openModal={this.openModal} /></div>

                                  if(module.module === 'heading')
                                  return <div className={'_scroll_'+module.id} key={module.id} ref={(el) => { if (el !== null && module.id === this.state.refId) { el.scrollIntoView({ behavior: this.state.scrollBehavior }); } }}><Heading data={module} /></div>

                                  if(module.module === 'spacer')
                                  return <div className={'_scroll_'+module.id} key={module.id} ref={(el) => { if (el !== null && module.id === this.state.refId) { el.scrollIntoView({ behavior: this.state.scrollBehavior }); } }}><Spacer data={module} /></div>

                                  if(module.module === 'typography')
                                  return <div className={'_scroll_'+module.id} key={module.id} ref={(el) => { if (el !== null && module.id === this.state.refId) { el.scrollIntoView({ behavior: this.state.scrollBehavior }); } }}><Typography data={module} /></div>

                                  if(module.module === 'content.grid')
                                  return <div className={'_scroll_'+module.id} key={module.id} ref={(el) => { if (el !== null && module.id === this.state.refId) { el.scrollIntoView({ behavior: this.state.scrollBehavior }); } }}><Content brand={brand} data={module} openModal={this.openModal} audio="0" audioStatus={this.state.audioStatus} audioProgress={this.state.audioProgress} playAudio={this.playAudio} pauseAudio={this.pauseAudio} /></div>

                                  return '';
                                })}
                                </div>
                              }
                              {searchItem.type === 'page' &&
                                <div>
                                {searchItem.modules.map(module => {
                                if(module.module === 'carousel')
                                  return <div className={'_scroll_'+module.id} key={module.id} ref={(el) => { if (el !== null && module.id === this.state.refId) { el.scrollIntoView({ behavior: this.state.scrollBehavior }); } }}><Carousel data={module} /></div>

                                  if(module.module === 'colors')
                                  return <div className={'_scroll_'+module.id} key={module.id} ref={(el) => { if (el !== null && module.id === this.state.refId) { el.scrollIntoView({ behavior: this.state.scrollBehavior }); } }}><Colors brand={brand} data={module} /></div>

                                  if(module.module === 'downloads')
                                  return <div className={'_scroll_'+module.id} key={module.id} ref={(el) => { if (el !== null && module.id === this.state.refId) { el.scrollIntoView({ behavior: this.state.scrollBehavior }); } }}><Downloads brand={brand.link} data={module} showcase={brand.showcase} /></div>

                                  if(module.module === 'grid')
                                  return <div className={'_scroll_'+module.id} key={module.id} ref={(el) => { if (el !== null && module.id === this.state.refId) { el.scrollIntoView({ behavior: this.state.scrollBehavior }); } }}><Grid data={module} openModal={this.openModal} /></div>

                                  if(module.module === 'do.dont')
                                  return <div className={'_scroll_'+module.id} key={module.id} ref={(el) => { if (el !== null && module.id === this.state.refId) { el.scrollIntoView({ behavior: this.state.scrollBehavior }); } }}><Dodont data={module} openModal={this.openModal} /></div>

                                  if(module.module === 'heading')
                                  return <div className={'_scroll_'+module.id} key={module.id} ref={(el) => { if (el !== null && module.id === this.state.refId) { el.scrollIntoView({ behavior: this.state.scrollBehavior }); } }}><Heading data={module} /></div>

                                  if(module.module === 'spacer')
                                  return <div className={'_scroll_'+module.id} key={module.id} ref={(el) => { if (el !== null && module.id === this.state.refId) { el.scrollIntoView({ behavior: this.state.scrollBehavior }); } }}><Spacer data={module} /></div>

                                  if(module.module === 'typography')
                                  return <div className={'_scroll_'+module.id} key={module.id} ref={(el) => { if (el !== null && module.id === this.state.refId) { el.scrollIntoView({ behavior: this.state.scrollBehavior }); } }}><Typography data={module} /></div>

                                  if(module.module === 'content.grid')
                                  return <div className={'_scroll_'+module.id} key={module.id} ref={(el) => { if (el !== null && module.id === this.state.refId) { el.scrollIntoView({ behavior: this.state.scrollBehavior }); } }}><Content brand={brand} data={module} openModal={this.openModal} audio="0" audioStatus={this.state.audioStatus} audioProgress={this.state.audioProgress} playAudio={this.playAudio} pauseAudio={this.pauseAudio} /></div>

                                  return '';
                                })}
                                </div>
                              }

                              {searchItem.type !== 'page' && searchItem.type !== 'masthead' &&
                                <div className="single-item-module">
                                  {searchItem.modules.map((module, index) => {
                                  if(module){
                                    if(module.module === 'carousel')
                                      return <div className={'_scroll_'+module.id+' '+(index === 1 ? 'center-those-modules' : '')} key={module.id} ref={(el) => { if (el !== null && module.id === this.state.refId) { el.scrollIntoView({ behavior: this.state.scrollBehavior }); } }}><Carousel data={module} /></div>

                                      if(module.module === 'colors')
                                      return <div className={'_scroll_'+module.id+' '+(index === 1 ? 'center-those-modules' : '')} key={module.id} ref={(el) => { if (el !== null && module.id === this.state.refId) { el.scrollIntoView({ behavior: this.state.scrollBehavior }); } }}><Colors brand={brand} data={module} /></div>

                                      if(module.module === 'downloads')
                                      return <div className={'_scroll_'+module.id+' '+(index === 1 ? 'center-those-modules' : '')} key={module.id} ref={(el) => { if (el !== null && module.id === this.state.refId) { el.scrollIntoView({ behavior: this.state.scrollBehavior }); } }}><Downloads brand={brand.link} data={module} showcase={brand.showcase} /></div>

                                      if(module.module === 'grid')
                                      return <div className={'_scroll_'+module.id+' '+(index === 1 ? 'center-those-modules' : '')} key={module.id} ref={(el) => { if (el !== null && module.id === this.state.refId) { el.scrollIntoView({ behavior: this.state.scrollBehavior }); } }}><Grid data={module} openModal={this.openModal} /></div>

                                      if(module.module === 'do.dont')
                                      return <div className={'_scroll_'+module.id+' '+(index === 1 ? 'center-those-modules' : '')} key={module.id} ref={(el) => { if (el !== null && module.id === this.state.refId) { el.scrollIntoView({ behavior: this.state.scrollBehavior }); } }}><Dodont data={module} openModal={this.openModal} /></div>

                                      if(module.module === 'heading')
                                      return <div className={'_scroll_'+module.id+' '+(index === 1 ? 'center-those-modules' : '')} key={module.id} ref={(el) => { if (el !== null && module.id === this.state.refId) { el.scrollIntoView({ behavior: this.state.scrollBehavior }); } }}><Heading data={module} /></div>

                                      if(module.module === 'spacer')
                                      return <div className={'_scroll_'+module.id+' '+(index === 1 ? 'center-those-modules' : '')} key={module.id} ref={(el) => { if (el !== null && module.id === this.state.refId) { el.scrollIntoView({ behavior: this.state.scrollBehavior }); } }}><Spacer data={module} /></div>

                                      if(module.module === 'typography')
                                      return <div className={'_scroll_'+module.id+' '+(index === 1 ? 'center-those-modules' : '')} key={module.id} ref={(el) => { if (el !== null && module.id === this.state.refId) { el.scrollIntoView({ behavior: this.state.scrollBehavior }); } }}><Typography data={module} /></div>

                                      if(module.module === 'content.grid')
                                      return <div className={'_scroll_'+module.id+' '+(index === 1 ? 'center-those-modules' : '')} key={module.id} ref={(el) => { if (el !== null && module.id === this.state.refId) { el.scrollIntoView({ behavior: this.state.scrollBehavior }); } }}><Content brand={brand} data={module} openModal={this.openModal} audio="0" audioStatus={this.state.audioStatus} audioProgress={this.state.audioProgress} playAudio={this.playAudio} pauseAudio={this.pauseAudio} /></div>

                                      return '';
                                    } else {
                                      return null;
                                    }
                                  })}
                                </div>
                              }

                              </div>
                            </div>
                          </div>
                          );
                        })}
                        {this.state.searchResultItems.length > 0 &&
                          <>
                          <div data-index={this.state.searchResultItems.length} className="final-card search-popup-result-carousel-item final-card-with-different-text">
                            <div className="search-popup-result-carousel-item-inner-overlay"></div>
                            <div className="search-popup-result-carousel-item-inner">
                              <div className="scene-hole" style={{display: "flex"}}>
                                <div className="hol-hole">
                                  <i className="hol-a"></i>
                                  <i className="hol-a"></i>
                                  <i className="hol-a"></i>
                                  <i className="hol-a"></i>
                                  <i className="hol-a"></i>
                                  <i className="hol-a"></i>
                                  <i className="hol-a"></i>
                                  <i className="hol-a"></i>
                                </div>
                              </div>
                              <div className="inner-quadrant-1"></div>
                              <div className="inner-quadrant-content">
                                <div>
                                  <h4>Based on keywords there is no more<br />relevant results!</h4>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                        }
                        {this.state.searchResultItems.length === 0 &&
                          <>
                          <div data-index={this.state.searchResultItems.length} className="final-card search-popup-result-carousel-item not-found">
                            <div className="search-popup-result-carousel-item-inner-overlay"></div>
                            <div className="search-popup-result-carousel-item-inner">
                              <div className="scene-hole" style={{display: "flex"}}>
                                <div className="hol-hole">
                                  <i className="hol-a"></i>
                                  <i className="hol-a"></i>
                                  <i className="hol-a"></i>
                                  <i className="hol-a"></i>
                                  <i className="hol-a"></i>
                                  <i className="hol-a"></i>
                                  <i className="hol-a"></i>
                                  <i className="hol-a"></i>
                                </div>
                              </div>
                              <div className="inner-quadrant-1"></div>
                              <div className="inner-quadrant-content">
                                <div>
                                  <h4>Based on keywords there is no<br />relevant results! </h4>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                        }
                      </div>
                      <div className="search-popup-result-carousel-item-intro-swipe-guide active">
                        <div className="search-popup-result-carousel-item-intro-swipe-guide-overlay-bg"></div>
                        <div className="search-popup-result-carousel-item-intro-swipe-guide-overlay">
                          <div>
                            <svg width="82" height="91" viewBox="0 0 82 91" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M20.3022 13.6748L12.2597 21.1581L20.3022 28.6415" stroke="white"/>
                            <path opacity="0.5" d="M9.0425 13.6748L1 21.1581L9.0425 28.6415" stroke="white"/>
                            <path d="M63.0744 54.9713V40.2048C63.0744 37.6502 61.0697 35.5801 58.5974 35.5801C56.1251 35.5801 54.1204 37.6514 54.1204 40.2048L54.7048 53.0977" stroke="white" strokeMiterlimit="10"/>
                            <path d="M47.4605 61.776L38.1122 52.2568C36.2869 50.3979 33.3483 50.4198 31.5502 52.3068C29.843 54.0962 29.7639 56.945 31.3672 58.832L37.0366 65.511C41.4357 70.6935 44.6576 76.8257 46.464 83.4583L47.8949 88.7031C48.0579 89.2975 48.1393 89.9139 48.1393 90.5315" stroke="white" strokeMiterlimit="10"/>
                            <path d="M63.1133 57.2554V43.9792C63.1133 41.4246 65.118 39.3545 67.5903 39.3545C70.0625 39.3545 72.0672 41.4246 72.0672 43.9792V58.653" stroke="white" strokeMiterlimit="10"/>
                            <path d="M72.0461 60.8931V47.6169C72.0461 45.0623 74.0508 42.9922 76.523 42.9922C78.9953 42.9922 81 45.0623 81 47.6169V61.1995C81 64.0947 80.7296 66.9825 80.1901 69.8253L79.4416 73.7714C78.902 76.6141 78.6316 79.502 78.6316 82.3971V89.7889" stroke="white" strokeMiterlimit="10"/>
                            <path d="M48.4533 9.29004C42.0956 9.29004 36.9362 14.6435 36.9362 21.2529C36.9362 26.3195 39.9681 30.6476 44.2467 32.3918L44.3719 27.9758C44.4451 25.3857 46.4982 23.3254 49.0071 23.3254C51.5159 23.3254 53.5383 25.3516 53.6399 27.9209L53.7957 31.8511C57.4651 29.8518 59.9693 25.8569 59.9693 21.2529C59.9693 14.6435 54.8111 9.29004 48.4522 9.29004H48.4533Z" fill="white"/>
                            <path d="M43.5313 57.541L44.3719 27.9726C44.4451 25.3826 46.4982 23.3223 49.0071 23.3223C51.5159 23.3223 53.5384 25.3484 53.6399 27.9177L54.6777 54.1027" stroke="white" strokeMiterlimit="10"/>
                            <g opacity="0.5">
                            <path d="M44.0661 41.0409C35.3979 38.9732 28.9292 30.9114 28.9292 21.2774C28.9292 10.0762 37.673 1 48.4534 1C59.2338 1 67.9776 10.0762 67.9776 21.2774C67.9776 27.3278 65.4262 32.7581 61.3813 36.4723" stroke="white"/>
                            </g>
                            </svg>
                            <p>Swipe left for more results!</p>
                          </div>
                        </div>
                      </div>
                      <div className="search-popup-bottom-dots">
                        {this.state.searchResultItems.map((searchItem, index) => {
                           return (
                            <div key={index} className={'search-popup-bottom-dot search-popup-bottom-dot-'+index+'  '+(index === 0 ? 'active' : '') } data-index={index}>
                            <span></span>
                          </div>
                            );
                         })}
                        <div className={'search-popup-bottom-dot search-popup-bottom-dot-last-one-dot search-popup-bottom-dot-'+this.state.searchResultItems.length } data-index={this.state.searchResultItems.length}>
                          <span></span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Search); // withRouter(Search);
