import React, { Component } from 'react';
import { NavLink, withRouter } from "react-router-dom";
import './Menu.scss';

import MultibrandSwitcher from '../sidebar/MultibrandSwitcher';
import Search from '../search/Search';

class Menu extends Component {
  constructor() {
    super();
    this.state = {
      expanded: [],
      brandId: 0,
      page: [],
      activeAnchor: 0
    }
  }

  // Check which block is expanded by default
  UNSAFE_componentWillReceiveProps(nextProps) {

    if (nextProps.activeAnchor) {
      this.setState({
        activeAnchor: nextProps.activeAnchor
      });
    }

    // Set active menu ID to be expanded by default
    if (nextProps.page) {
      this.setState({
        page: nextProps.page
      });

      // On page change, set menu fold
      if (nextProps.brand.menu_fold === 'yes' && nextProps.brand.id && nextProps.brand.id === this.state.brandId && nextProps.page.id !== this.state.page.id) {
        let arr = [];

        for (var firstLevel=0; firstLevel < nextProps.menu.length; firstLevel++) {
          for (var secondLevel=0; secondLevel < nextProps.menu[firstLevel].pages.length; secondLevel++) {
            if (nextProps.menu[firstLevel].pages[secondLevel].id === nextProps.page.id) {
              if (!arr.includes(nextProps.menu[firstLevel].id)) {
                arr.push(nextProps.menu[firstLevel].id);
              }
            }

            if (nextProps.menu[firstLevel].pages[secondLevel].id === nextProps.page.id || nextProps.menu[firstLevel].pages[secondLevel].id === nextProps.page.parent_id) {
              if (!arr.includes(nextProps.menu[firstLevel].id)) {
                arr.push(nextProps.menu[firstLevel].id);
              }
              if (!arr.includes(nextProps.menu[firstLevel].pages[secondLevel].id)) {
                arr.push(nextProps.menu[firstLevel].pages[secondLevel].id);
              }
            }
          }
        }

        this.setState({
          expanded: arr
        });
      }
    }

    if (nextProps.brand.id && nextProps.brand.id !== this.state.brandId) {
      let arr = [];

      this.setState({
        brandId: nextProps.brand.id
      });

      // Set expanded menu items on brand load
      for (var firstLevel=0; firstLevel < nextProps.menu.length; firstLevel++) {
        if (!arr.includes(nextProps.menu[firstLevel].id) && nextProps.brand.menu_fold !== 'yes') {
          arr.push(nextProps.menu[firstLevel].id);

          for (var secondLevel=0; secondLevel < nextProps.menu[firstLevel].pages.length; secondLevel++) {
            if (!arr.includes(nextProps.menu[firstLevel].pages[secondLevel].id) && nextProps.brand.menu_fold !== 'yes') {
              arr.push(nextProps.menu[firstLevel].pages[secondLevel].id);
            }
          }
        }

        // Set active level expanded if menu set as fold by default
        if (!arr.includes(nextProps.menu[firstLevel].id) && nextProps.brand.menu_fold === 'yes') {
          if (nextProps.menu[firstLevel].page_id === nextProps.page.page_id || nextProps.menu[firstLevel].id === nextProps.page.parent_id || nextProps.menu[firstLevel].id === nextProps.page.main_parent_id) {
            arr.push(nextProps.menu[firstLevel].id);

            for (var secondLevel=0; secondLevel < nextProps.menu[firstLevel].pages.length; secondLevel++) {
              if (nextProps.menu[firstLevel].pages[secondLevel].id === nextProps.page.id || nextProps.menu[firstLevel].pages[secondLevel].id === nextProps.page.parent_id) {
                arr.push(nextProps.menu[firstLevel].pages[secondLevel].id);
              }
            }
          }
        }
      }

      this.setState({
        expanded: arr
      });
    }
  }

  _toggleMenu = (id) => {
    let arr = this.state.expanded;

    if (!arr.includes(id)) {
      arr.push(id);
    } else {
      for ( var i = 0; i < arr.length; i++) {
        if (arr[i] === id) {
          arr.splice(i, 1);
        }
      }
    }

    this.setState({expanded:arr});
  }

  // Check if menu section should be expanded by default
  _checkState = (id) => {
    return this.state.expanded.includes(id) || id === this.state.page.id || id === this.state.page.parent_id;
  }

  render () {
    let props = this.props;
    let brand = props.brand;

    return (
      <div>
        <div className={'col-lg-2 brand-menu' + (props.menuopen ? ' open': '')+ (brand.menu_scrollbar !== 'yes' ? ' hide-menu-scrollbar': '')} style={{ maxWidth: brand.menu_width+'px', borderColor: brand.menu_divider !== 'yes' ? 'transparent' : brand.menu_divider_color}}>
          {brand.multibrand === 'yes' && ( <MultibrandSwitcher brand={brand}></MultibrandSwitcher> )}
          <div className={'brand-menu-wrapper' + (brand.showcase ? ' showcase' : '') + (props.showAudioPlayer ? ' audio-playing' : '') + (brand.multibrand === 'yes' ? ' multibrand' : '')}>
            <div className="scroll-wrapper">
              <div className="scroll-inner">
                <div className="brand-data" style={{ color: brand.menu_item_color}}>
                  <div className="logo-box"  style={{ paddingTop: brand.logo_y+'px'}}>
                    <div className="resizable-logo" style={{ width: brand.logo_width === '100%' ? '169px' : brand.logo_width+'px', left: brand.logo_x === 'auto' ? '35px' : brand.logo_x+'px'}}>
                      {!brand.orig_image && brand.image && ( <NavLink to={'/' + (!props.customDomain ? brand.link : '')}><img src={brand.image} alt={brand.title} /></NavLink> )}
                      {brand.orig_image && ( <NavLink to={'/' + (!props.customDomain ? brand.link : '')}><img src={brand.orig_image} alt={brand.title} /></NavLink> )}
                    </div>
                  </div>
                  {brand.menu_title === 'yes' && ( <div className="title-box"><h6 style={{ width: brand.logo_title_width+'px', left: brand.logo_title_x+'px', marginTop: brand.logo_title_y+'px'}}>{brand.title}</h6></div> )}
                </div>
                <div className="menu-bar-content">
                  <div style={{marginTop: brand.menu_margin_top === 'auto' && brand.menu_title === 'yes' ? '5px' : brand.menu_margin_top+'px'}} className="menu-bar-grabber"></div>

                  <Search brand={brand} />

                  <div className="brand-menu__padding">
                    {/* First level menu */}
                    {props.menu.map(firstLevel =>
                    <div style={{ marginLeft: brand.menu_padding_left+'px', marginRight: brand.menu_padding_right+'px'}} className={this._checkState(firstLevel.id) ? 'brand-block' : 'brand-block collapsed'} key={firstLevel.id}>
                      <div
                        className={firstLevel.page_id === this.state.page.page_id || firstLevel.id === this.state.page.parent_id || firstLevel.id === this.state.page.main_parent_id ? 'brand-block__section active' : 'brand-block__section'}
                        onClick={() => { this._toggleMenu(firstLevel.id) }}>

                        {brand.menu_dropdown === 'yes' && firstLevel.type === 'section' && (
                          <svg className="ico-menu-arrow" width="6" height="4" viewBox="0 0 6 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M4.96823 0H0.968624C0.562508 0 0.325808 0.458545 0.561034 0.789603L2.45558 3.456C2.64877 3.72789 3.04894 3.73801 3.25561 3.47622L5.36067 0.809822C5.61953 0.481934 5.38598 0 4.96823 0Z" fill="#0040FF"/>
                          </svg>
                        )}

                        {firstLevel.protected === 1 && firstLevel.type === 'page' && !brand.locked && (
                          <svg className="ico-page-locked" width="9" height="10" viewBox="0 0 9 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect x="0.5" y="4" width="7.5" height="5.5" rx="2" stroke="#15161B"/>
                            <path d="M2 4.5V2.59361C2 1.43734 3.00736 0.5 4.25 0.5C5.49264 0.5 6.5 1.43734 6.5 2.59361C6.5 2.59361 6.5 3.90182 6.5 4.5" stroke="#15161B"/>
                          </svg>
                        )}

                        {firstLevel.type === 'section' && (
                          <span>{firstLevel.title}</span>
                        )}

                        {firstLevel.type === 'page' && (
                          <NavLink to={'/' + (!props.customDomain ? brand.link + '/' : '') + firstLevel.url}>
                            {firstLevel.title}
                          </NavLink>
                        )}
                      </div>

                      {/* Second level menu */}
                      {firstLevel.pages.length > 0 && (!firstLevel.anchors || firstLevel.anchors && firstLevel.id === this.state.page.id) && (
                      <div className="brand-block__sub">
                        {firstLevel.pages.map(secondLevel =>
                          <div className={this._checkState(secondLevel.id) ? 'brand-block' : 'brand-block collapsed'} key={secondLevel.id}>
                            <div
                              className={secondLevel.id === this.state.page.id || secondLevel.id === this.state.page.parent_id ? 'brand-block__section active' : 'brand-block__section'}
                              onClick={() => { this._toggleMenu(secondLevel.id) }}>

                              {brand.menu_dropdown === 'yes' && secondLevel.type === 'section' && (
                                <svg className="ico-menu-arrow" width="6" height="4" viewBox="0 0 6 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M4.96823 0H0.968624C0.562508 0 0.325808 0.458545 0.561034 0.789603L2.45558 3.456C2.64877 3.72789 3.04894 3.73801 3.25561 3.47622L5.36067 0.809822C5.61953 0.481934 5.38598 0 4.96823 0Z" fill="#0040FF"/>
                                </svg>
                              )}

                              {secondLevel.protected === 1 && secondLevel.type === 'page' && !brand.locked && (
                                <svg className="ico-page-locked" width="9" height="10" viewBox="0 0 9 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <rect x="0.5" y="4" width="7.5" height="5.5" rx="2" stroke="#15161B"/>
                                  <path d="M2 4.5V2.59361C2 1.43734 3.00736 0.5 4.25 0.5C5.49264 0.5 6.5 1.43734 6.5 2.59361C6.5 2.59361 6.5 3.90182 6.5 4.5" stroke="#15161B"/>
                                </svg>
                              )}

                              {secondLevel.type === 'section' && (
                                <span>{secondLevel.title}</span>
                              )}

                              {secondLevel.type === 'page' && (
                                <NavLink
                                  className={secondLevel.id === this.state.page.id || secondLevel.anchor == this.state.activeAnchor ? 'active' : ''}
                                  to={'/' + (!props.customDomain ? brand.link + '/' : '') + secondLevel.url}>
                                  {secondLevel.title}
                                </NavLink>
                              )}

                              {secondLevel.type === 'anchor' && firstLevel.id === this.state.page.id && (
                                <NavLink
                                  className={secondLevel.id === this.state.page.id || secondLevel.anchor == this.state.activeAnchor ? 'active' : ''}
                                  to={'/' + (!props.customDomain ? brand.link + '/' : '') + secondLevel.url}>
                                  {secondLevel.title}
                                </NavLink>
                              )}
                            </div>

                            {/* Third level menu */}
                            {secondLevel.pages.length > 0 && (!secondLevel.anchors || secondLevel.anchors && secondLevel.id === this.state.page.id) && (
                              <div className="brand-block__sub">
                                {secondLevel.pages.map(thirdLevel =>
                                  <div key={thirdLevel.id}>
                                    <div
                                      className={thirdLevel.id === this.state.page.id ? 'brand-block__sub__item active' : 'brand-block__sub__item'}>

                                      {thirdLevel.protected === 1 && thirdLevel.type === 'page' && !brand.locked && (
                                        <svg className="ico-page-locked" width="9" height="10" viewBox="0 0 9 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                          <rect x="0.5" y="4" width="7.5" height="5.5" rx="2" stroke="#15161B"/>
                                          <path d="M2 4.5V2.59361C2 1.43734 3.00736 0.5 4.25 0.5C5.49264 0.5 6.5 1.43734 6.5 2.59361C6.5 2.59361 6.5 3.90182 6.5 4.5" stroke="#15161B"/>
                                        </svg>
                                      )}

                                      <NavLink
                                        className={thirdLevel.id === this.state.page.id || thirdLevel.anchor == this.state.activeAnchor ? 'active' : ''}
                                        to={'/' + (!props.customDomain ? brand.link + '/' : '') + thirdLevel.url}>
                                        {thirdLevel.title}
                                      </NavLink>
                                    </div>

                                    {thirdLevel.pages.length > 0 && thirdLevel.id === this.state.page.id && thirdLevel.protected !== 1 && (
                                      <div className="brand-block__sub">
                                        {thirdLevel.pages.map(fourthLevel =>
                                          <div className="brand-block__sub__item" key={fourthLevel.id}>
                                            <NavLink
                                              className={fourthLevel.id === this.state.page.id || fourthLevel.anchor == this.state.activeAnchor ? 'active' : ''}
                                              to={'/' + (!props.customDomain ? brand.link + '/' : '') + fourthLevel.url}>
                                              {fourthLevel.title}
                                            </NavLink>
                                          </div>
                                        )}
                                      </div>
                                    )}
                                  </div>
                                )}
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                      )}
                    </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Menu);
