import React, { Component } from 'react';
import axios from 'axios';
import './Downloads.scss';

import {localAnalyticsLogEvent} from 'localAnalytics';

import ShareLocation from '../../partials/share/ShareLocation';
import VideoBackground from '../../partials/video/VideoBackground';
import { calculatePadding } from '../../../utils';

const windowWidth = window.innerWidth;

class Downloads extends Component {

  constructor() {
    super();
    this.state = {
      data: [],
      items: [],
      showcase: false,
      brand: false,
      downloadInterval: '',
      downloadProcess: false,
    }
  }

  componentDidMount() {
    this.setState({
      data: this.props.data,
      items: this.props.data.items
    });
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    this.setState({
      showcase: newProps.showcase,
      brand: newProps.brand,
      brandId: newProps.brandId
    });
  }

  // Triger right mouse click in showcase/modal
  openModal = (e) => {
    e.preventDefault();
    if (window.CustomEvent) {
        window.dispatchEvent(new CustomEvent('contextmenu'));
    } else if (document.createEvent) {
        var ev = document.createEvent('HTMLEvents');
        ev.initEvent('contextmenu', true, false);
        window.dispatchEvent(ev);
    } else {
        window.fireEvent('oncontextmenu');
    }
  };

  // Prepare Dash asset download
  createDashDownload = (attachment) => {
    if (this.state.downloadProcess) return false;

    this.setState({ downloadProcess: attachment.id });

    let self = this;
    let asset_id = attachment.filename.split('file=');

    asset_id = asset_id[1] ? asset_id[1] : asset_id[0];

    axios
      .get(window.API + this.state.brand + '?action=create-dash-asset&id=item&asset_id=' + asset_id, {
        headers: {
          Pragma: 'no-cache',
          Authorization: localStorage.getItem('authToken') || ''
        }
      }).then(function (response) {
        let intervalId = setInterval(function(){
          self.getDashDownload(response.data.id);
        }, 1000);

        self.setState({ downloadInterval: intervalId })
      });
  };

  getDashDownload = async (id) => {
    try {
      const response = await axios.get(window.API + this.state.brand + '?action=get-dash-asset&id=' + id, {
        headers: {
          Pragma: 'no-cache',
          Authorization: localStorage.getItem('authToken') || ''
        }
      });

      if (response.data.type === 'COMPLETED') {
        clearInterval(this.state.downloadInterval);
        this.setState({ downloadProcess: false });
        window.location.href = response.data.result.successes.item.result.url;
      }
    } catch (err) {
      console.error(err);
    }
  };

  createAssetbankDownload = (attachment) => {
    if (this.state.downloadProcess) return false;

    this.setState({ downloadProcess: attachment.id });

    let self = this;
    let asset_id = attachment.filename;

    axios
      .get(window.API + this.state.brand + '?action=get-assetbank-asset&id=item&id=' + asset_id, {
        headers: {
          Pragma: 'no-cache',
          Authorization: localStorage.getItem('authToken') || ''
        }
      }).then(function (response) {
        self.setState({ downloadProcess: false });
        window.location.href = response.data.url;
      });
  };

  trackDownloadEvent = (token) => {
    const d = new Date();
    const monthNames = ["January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December"
    ];

    let data = this.state.data;
    let index = data.items.findIndex(object => {
      return object.token === token;
    });

    data.items[index].downloaded = monthNames[d.getMonth()] + ' ' + d.getDate() + ', ' + d.getFullYear();

    this.setState({
      data: data
    });

    localAnalyticsLogEvent("public_item_downloaded", {brandId: window.loadedBrandId, label: token});
  }

  render () {
    return (
      <div>
        <div
          className={'module'}
          style={{
            paddingTop: calculatePadding(windowWidth, this.state.data.row_padding_top)+'px',
            paddingBottom: calculatePadding(windowWidth, this.state.data.row_padding_bottom)+'px',
            backgroundColor:this.state.data.background,
            backgroundImage:this.state.data.bg_image ? 'url('+this.state.data.bg_image+')' : 'none',
            backgroundPosition:this.state.data.bg_image_x+'% '+this.state.data.bg_image_y+'%'
          }}
        >
          <VideoBackground src={this.state.data.bg_video} />
          {this.state.data.dim === 1 && (
            <div className="module-bg-dim"></div>
          )}
          <ShareLocation module={this.state.data.id} />
          <div className="module_downloads row">
            <div className="col-12">
              {this.state.data.text && (
              <div className="dynamic-content" style={{'width':this.state.data.text_width+'px', 'left':this.state.data.text_left+'px', 'top':this.state.data.text_top+'px'}}>
                <div className="editor-content" dangerouslySetInnerHTML={{__html: this.state.data.text}}></div>
              </div>
              )}

              <div className="module_downloads__items">
                {this.state.items.map(item =>
                <div key={item.id} className="module_downloads__item" style={{backgroundColor:item.background_color, borderRadius:item.border_radius, border:item.border_width+'px solid '+item.border_color}}>
                  {this.state.showcase && (
                  <a href="#/" onClick={this.openModal}>
                    <h6 style={{color:item.title_color}}>
                      {item.title}
                    </h6>
                    <div className="actions">
                      <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="20" height="20" viewBox="0 0 20 20"><defs><path id="wee3a" d="M1468 2620v4a2 2 0 0 1-2 2h-14a2 2 0 0 1-2-2v-4"/><path id="wee3b" d="M1454 2615l5 5 5-5"/><path id="wee3c" d="M1459 2620v-12"/></defs><g><g transform="translate(-1449 -2607)"><use fill="#fff" fillOpacity="0" stroke={item.title_color} strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="50" xlinkHref="#wee3a"/></g><g transform="translate(-1449 -2607)"><use fill="#fff" fillOpacity="0" stroke={item.title_color} strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="50" xlinkHref="#wee3b"/></g><g transform="translate(-1449 -2607)"><use fill="#fff" fillOpacity="0" stroke={item.title_color} strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="50" xlinkHref="#wee3c"/></g></g></svg>
                    </div>
                  </a>
                  )}

                  {!this.state.showcase && item.source !== 'dropbox' && item.source !== 'google' && item.source !== 'dash' && (
                  <a href={item.filename} download rel="noopener noreferrer" onClick={() => { this.trackDownloadEvent(item.token) }}>
                    <h6 style={{color:item.title_color}}>
                      {item.title}
                    </h6>
                    <div className="actions">
                      <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="20" height="20" viewBox="0 0 20 20"><defs><path id="wee3a" d="M1468 2620v4a2 2 0 0 1-2 2h-14a2 2 0 0 1-2-2v-4"/><path id="wee3b" d="M1454 2615l5 5 5-5"/><path id="wee3c" d="M1459 2620v-12"/></defs><g><g transform="translate(-1449 -2607)"><use fill="#fff" fillOpacity="0" stroke={item.title_color} strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="50" xlinkHref="#wee3a"/></g><g transform="translate(-1449 -2607)"><use fill="#fff" fillOpacity="0" stroke={item.title_color} strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="50" xlinkHref="#wee3b"/></g><g transform="translate(-1449 -2607)"><use fill="#fff" fillOpacity="0" stroke={item.title_color} strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="50" xlinkHref="#wee3c"/></g></g></svg>
                    </div>
                  </a>
                  )}

                  {!this.state.showcase && item.source === 'dropbox' && (
                  <a href={item.filename} onClick={(e) => { this.trackDownloadEvent(item.token) }} target={'_blank'} rel="noopener noreferrer">
                    <h6 style={{color:item.title_color}}>
                      {item.title}
                    </h6>
                    <div className="actions">
                      <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="20" height="20" viewBox="0 0 20 20"><defs><path id="wee3a" d="M1468 2620v4a2 2 0 0 1-2 2h-14a2 2 0 0 1-2-2v-4"/><path id="wee3b" d="M1454 2615l5 5 5-5"/><path id="wee3c" d="M1459 2620v-12"/></defs><g><g transform="translate(-1449 -2607)"><use fill="#fff" fillOpacity="0" stroke={item.title_color} strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="50" xlinkHref="#wee3a"/></g><g transform="translate(-1449 -2607)"><use fill="#fff" fillOpacity="0" stroke={item.title_color} strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="50" xlinkHref="#wee3b"/></g><g transform="translate(-1449 -2607)"><use fill="#fff" fillOpacity="0" stroke={item.title_color} strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="50" xlinkHref="#wee3c"/></g></g></svg>
                    </div>
                  </a>
                  )}

                  {!this.state.showcase && item.source === 'google' && (
                  <a href={item.filename} onClick={(e) => { this.trackDownloadEvent(item.token) }} target={'_blank'} rel="noopener noreferrer">
                    <h6 style={{color:item.title_color}}>
                      {item.title}
                    </h6>
                    <div className="actions">
                      <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="20" height="20" viewBox="0 0 20 20"><defs><path id="wee3a" d="M1468 2620v4a2 2 0 0 1-2 2h-14a2 2 0 0 1-2-2v-4"/><path id="wee3b" d="M1454 2615l5 5 5-5"/><path id="wee3c" d="M1459 2620v-12"/></defs><g><g transform="translate(-1449 -2607)"><use fill="#fff" fillOpacity="0" stroke={item.title_color} strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="50" xlinkHref="#wee3a"/></g><g transform="translate(-1449 -2607)"><use fill="#fff" fillOpacity="0" stroke={item.title_color} strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="50" xlinkHref="#wee3b"/></g><g transform="translate(-1449 -2607)"><use fill="#fff" fillOpacity="0" stroke={item.title_color} strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="50" xlinkHref="#wee3c"/></g></g></svg>
                    </div>
                  </a>
                  )}

                  {!this.state.showcase && item.source === 'dash' && (
                  <a href="#/" onClick={(e) => { e.preventDefault(); this.createDashDownload(item); this.trackDownloadEvent(item.token) }}>
                    <h6 style={{color:item.title_color}}>
                      {item.title}
                    </h6>
                    <div className="actions">
                      {this.state.downloadProcess !== item.id && (
                        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="20" height="20" viewBox="0 0 20 20"><defs><path id="wee3a" d="M1468 2620v4a2 2 0 0 1-2 2h-14a2 2 0 0 1-2-2v-4"/><path id="wee3b" d="M1454 2615l5 5 5-5"/><path id="wee3c" d="M1459 2620v-12"/></defs><g><g transform="translate(-1449 -2607)"><use fill="#fff" fillOpacity="0" stroke={item.title_color} strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="50" xlinkHref="#wee3a"/></g><g transform="translate(-1449 -2607)"><use fill="#fff" fillOpacity="0" stroke={item.title_color} strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="50" xlinkHref="#wee3b"/></g><g transform="translate(-1449 -2607)"><use fill="#fff" fillOpacity="0" stroke={item.title_color} strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="50" xlinkHref="#wee3c"/></g></g></svg>
                      )}
                      {this.state.downloadProcess === item.id && (
                        <div className="spinner-icon spinner-icon--20"></div>
                      )}
                    </div>
                  </a>
                  )}

                  <div className="file-info" style={{ top: '-'+(item.border_width*1+1)+'px'}}>
                    <div className="file-info__title">{item.preview_title}</div>
                    <div className="file-info__details">
                        <p>
                          Kind: {item.type}<br/>Size: {item.size !== 0 ? item.size : 'Unknown'}
                          {item.size_x && item.size_y && (
                          <span><br/>Dimensions: {item.size_x} x {item.size_y}</span>
                          )}
                        </p>
                        <p>
                          Uploaded: {item.uploaded}<br/>Last downloaded: {item.downloaded}
                        </p>
                        {item.preview && (
                        <p className="preview">
                          <img src={item.preview} alt=""/>
                        </p>
                        )}
                    </div>
                  </div>
                </div>
                )}
              </div>

            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Downloads;
